import React from 'react';

const Footer = () => {
    return (
        <div className='footer small text-center text-muted bg-white p-3 mt-3'>
            Powered by Phox Health, Inc.
        </div>
    );
};

export default Footer;

const Unauthorized = () => {
    return (
        <div className="text-center mt-5 d-flex flex-column bg-white justify-content-center" style={{ height: "70vh" }}>
            <div className="mb-4">
                <svg width="100" height="100" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="45" fill="#f0f0f0" />
                    <path d="M50 25 C 35 25, 25 35, 25 50 C 25 65, 35 75, 50 75 C 65 75, 75 65, 75 50 C 75 35, 65 25, 50 25" fill="#ffffff" />
                    <rect x="30" y="40" width="40" height="20" fill="#333333" />
                    <circle cx="38" cy="50" r="3" fill="#ffffff" />
                    <circle cx="62" cy="50" r="3" fill="#ffffff" />
                </svg>
            </div>
            <h1 className="display-1 text-primary">401</h1>
            <h2 className="mb-3">Unauthorized</h2>
            <p className="text-muted mb-4">You don't have permission to access this page.</p>
        </div>
    );
};

export default Unauthorized;
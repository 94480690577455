import React from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import shipmentStatus from "../data/shipment-status.json"

export const ElasticFacetFilter = ({ name, item, facets, facetName, dark, onChange }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    return (
        <Col className='col-auto px-1 my-1'>
            <Dropdown className='dropdown'>
                <button className={`btn btn-sm ${color} dropdown-toggle`} type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {name}: {item || 'All'}
                </button>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton' >

                    <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
                        {
                            facets && facets[facetName]?.buckets && facets[facetName]?.buckets?.sort((a, b) => a.key.localeCompare(b.key, undefined, { sensitivity: 'accent' })).map((item, index) => {
                                return <button key={index} className='dropdown-item' onClick={() => onChange(item.key)} >
                                    {name === 'Status' ? shipmentStatus[item.key] : item.key} ({item?.doc_count})
                                </button>
                            })
                        }
                    </div>
                    <hr className='my-1' />
                    <button className='dropdown-item' onClick={() => onChange()}>Clear</button>
                </div>
            </Dropdown>
        </Col>
    )
}





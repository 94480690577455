/* eslint-disable react-hooks/exhaustive-deps */

import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { storeApp, storeCarrier, storeHost, storeShipperGroup, storeShippers, storeUser } from '../../stores/slice';
import { useDispatch } from 'react-redux';

const Logout = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate();

	useEffect(() => logout(), [])

	function logout() {
		dispatch(storeHost({}));
		dispatch(storeUser({}));
		dispatch(storeApp({}));
		dispatch(storeShipperGroup({}));
		dispatch(storeShippers({}));
		dispatch(storeCarrier({}));
		Auth.signOut().then((res) => navigate('/'));
	}
}

export default Logout;

import React from 'react';
import { Link } from 'react-router-dom';

const ListGroupItem = ({ name, value, onClick, link, url }) => {
	return (
		<div className='list-group-item'>
			<div className='row align-items-center'>
				<div className='col'>
					<h5 className='mb-0'>{name}</h5>
				</div>
				<div className='col-auto'>
					{url ? <Link to={url} rel="noreferrer">{value}</Link> : <span className='small text-muted'>{value}</span>}
					{link && <Link className={`ms-2`} onClick={onClick}><i className={link} /></Link>}
				</div>
			</div>
		</div>
	);
};

export default ListGroupItem;
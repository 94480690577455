import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { toTitleCase } from '../helpers';

const SelectRole = ({ value, onChange }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant='light' size='sm'>{toTitleCase(value) || '-'}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => onChange('OWNER')}>Owner</Dropdown.Item>
        <Dropdown.Item onClick={() => onChange('EDITOR')}>Editor</Dropdown.Item>
        <Dropdown.Item onClick={() => onChange('VIEWER')}>Viewer</Dropdown.Item>
        <hr />
        <Dropdown.Item onClick={() => onChange('REMOVE')}>Remove</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectRole;

import swal from "sweetalert";
export const showLoader = (htmlContent = `<div class="spinner-border text-dark" role="status"></div>`) => {
    swal({
        content: {
            element: "div",
            attributes: {
                innerHTML: htmlContent
            }
        },
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
    });
};

export const hideLoader = () => {
    swal.close();
};
/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, ListGroup, ListGroupItem, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import uniqid from 'uniqid';
import PageHeader from '../../components/PageHeader';
import SelectRole from '../../components/SelectRole';
import Spinner from '../../components/Spinner';
import { handleApiError, isEditor, isOwner, isViewer, toTitleCase } from '../../helpers';
import ReactGA from "react-ga4"

const getUserQuery = /* GraphQL */ `
  query GetUser ($id: ID!) {
    getUser (id: $id) {
		id
		name
		email
		phone
		role
		shipperGroups {
			items {
				id
				role
				shipperGroup {
					id
					name
				}
			}
		}
		shippers {
			items {
				id
				role
				shipper {
					id
					name
				}
			}
		}
		carriers {
			items {
				id
				role
				carrier {
					id
					name
				}
			}
		}
    }
  }
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

const updateShipperUserMutation = /* GraphQL */ `
  mutation UpdateShipperUser($input: UpdateShipperUserInput!) {
    updateShipperUser(input: $input) {
      id
    }
  }
`;

const deleteShipperUserMutation = /* GraphQL */ `
  mutation DeleteShipperUser($input: DeleteShipperUserInput!) {
    deleteShipperUser(input: $input) {
      id
    }
  }
`;

const createShipperUser = /* GraphQL */ `
  mutation CreateShipperUser($input: CreateShipperUserInput!) {
    createShipperUser(input: $input) {
      id
    }
  }
`;


const UserOverview = () => {

    const { userId } = useParams();
    const myShippers = useSelector((state) => state.slice.SHIPPERS)
    const [user, setUser] = useState([]);
    const [newUserRole, setNewUserRole] = useState([{ id: '', name: '', role: 'VIEWER' }]);

    const [spinner, showSpinner] = useState(true);
    const [shipperModal, showShipperModal] = useState(false);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/user/${userId}/overview`,
        })
    }, [])

    useEffect(() => getUser(), [])


    const getUser = () => {
        showSpinner(true);
        API.graphql({ query: getUserQuery, variables: { id: userId } })
            .then((res) => setUser(res?.data?.getUser))
            .catch(handleApiError)
            .finally(() => showSpinner(false))
    }

    const updateShipperRole = (id, role) => {
        showSpinner(true);
        if (role === 'REMOVE') {
            API.graphql({ query: deleteShipperUserMutation, variables: { input: { id: id } } })
                .then(async (response) => {
                    await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
                    getUser();
                    toast.success(`${user.name}'s role has been updated`);
                }).catch(handleApiError)
        } else {
            API.graphql({ query: updateShipperUserMutation, variables: { input: { id: id, role: role } } })
                .then(async (response) => {
                    await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
                    getUser();
                    toast.success(`${user.name}'s role has been updated`);
                }).catch(handleApiError)
        }
    }


    //#region Create    
    const handleCreateUser = () => {
        showShipperModal(false);
        showSpinner(true)
        try {
            newUserRole.forEach(async (item) => {
                await API.graphql({ query: createShipperUser, variables: { input: { id: uniqid(), shipperId: item.id, userId: userId, role: item.role, } } })
                await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
                getUser()
                toast.success('Role added successfully')
                showSpinner(false)
                setNewUserRole([{ id: '', name: '', role: 'VIEWER' }])
            })
        } catch (error) {
            console.error(error)
            showSpinner(false)
            toast.error('Oops! Looks like there was an error trying to process your request. Please try again.')
        }
    }
    //#endregion

    //#region Search
    const searchShipper = async (keyword) => {
        let items = []
        myShippers.forEach(item => {
            if (item?.role === 'OWNER') {
                items.push({
                    label: item.shipper.name,
                    value: item.shipper.id
                })
            }
        });

        let shippersIds = user?.shippers?.items?.map(item => item.shipper.id)
        let shipperList = items.filter(item => {
            return !shippersIds.includes(item.value);
        });

        if (keyword) {
            shipperList = shipperList.filter(item => item.label.toLowerCase().includes(keyword.toLowerCase()))
            return shipperList;
        } else {
            return shipperList;
        }
    };
    //#endregion

    //#region Add role 
    const addRole = (e) => {
        let shipperGroup = newUserRole ? [...newUserRole] : [];
        shipperGroup.push({
            id: 'NEW',
            name: '',
            role: 'VIEWER'
        })
        setNewUserRole(shipperGroup);
    }

    const removeRole = (index) => {
        let shipperGroup = newUserRole ? [...newUserRole] : [];
        shipperGroup.splice(index, 1);
        setNewUserRole(shipperGroup);
    }

    const handleRoleChange = (index, event, name) => {
        let item = newUserRole ? [...newUserRole] : [];
        let accessIds = item.map((role) => role?.id)
        if (accessIds.includes(event?.value)) {
            toast.error('Shipper is already selected. Please choose a different one.')
        } else {
            if (name === 'id') {
                item[index].id = event.value;
                item[index].name = event.label;
            }
            if (name === 'role') item[index].role = event;
            setNewUserRole(item);
        }
    }
    //#endregion

    const menus = [{ 'name': 'Overview', 'url': `/user/${userId}/overview`, 'active': true }]

    const userRoles = myShippers.reduce((acc, myShipper) => {
        if (myShipper.role === "OWNER") {
            const myShipperId = myShipper.shipper.id;
            const matchingRole = user?.shippers?.items?.find(accessRole => accessRole.shipper.id === myShipperId);
            if (matchingRole) acc.push(matchingRole?.shipper?.id);
        }
        return acc;
    }, []);

    return (
        <>
            <PageHeader name={user?.name || '-'} pageTitle={"User Overview"} title='User' cover='/img/profile-cover-5.jpg' menus={menus} />
            <Spinner display={spinner}>
                <Container fluid>
                    <Row>
                        <Col lg={8}>

                            <Card>
                                <Card.Header>
                                    <h4 className='mb-0'>Shipper Roles</h4>
                                    <Button variant='light' size='sm' onClick={() => showShipperModal(true)}>Add More</Button>
                                </Card.Header>
                                <Card.Body className='py-0'>
                                    <ListGroup className='list-group-flush'>
                                        {
                                            user?.shippers?.items?.map((item, index) =>
                                                <ListGroupItem index={index} key={index}>
                                                    <Row>
                                                        <Col>
                                                            {item.shipper?.name}
                                                        </Col>
                                                        <Col className='col-auto text-muted'>
                                                            {
                                                                userRoles.includes(item?.shipper?.id) ?
                                                                    <SelectRole value={item.role} onChange={(role) => updateShipperRole(item.id, role)} />
                                                                    : <Dropdown >
                                                                        <Dropdown.Toggle variant='light' size='sm' disabled>{toTitleCase(item?.role) || '-'}
                                                                        </Dropdown.Toggle>
                                                                    </Dropdown>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                            )
                                        }
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col lg={4}>
                            <Card>
                                <Card.Body className='py-0'>
                                    <ListGroup className='list-group-flush'>
                                        <ListGroupItem>
                                            <Row>
                                                <Col>Email</Col>
                                                <Col className='col-auto text-muted'>
                                                    {user?.email || '-'}
                                                </Col>
                                            </Row>
                                        </ListGroupItem>

                                        <ListGroupItem>
                                            <Row>
                                                <Col>Phone</Col>
                                                <Col className='col-auto text-muted'>
                                                    {user?.phone || '-'}
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Spinner>


            {/* shipper Modal  */}
            <Modal show={shipperModal}>
                <Modal.Header>
                    <Modal.Title>
                        Shipper Roles
                    </Modal.Title>
                    <button className='btn btn-sm btn-light' onClick={() => addRole()}>
                        Add More
                    </button>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <Table className='table-sm table-fixed mb-0'>
                        <thead>
                            <tr>
                                <th className='text-start'>Name</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody className='list font-size-base'>
                            {
                                newUserRole?.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <AsyncSelect key={index} value={{ label: item.name || 'Choose Shipper', value: item.id }}
                                                type='text' loadOptions={searchShipper} defaultOptions
                                                onChange={(e) => handleRoleChange(index, e, 'id')} />
                                        </td>
                                        <td>
                                            <button className={`btn btn-${isOwner(newUserRole[index].role) ? 'success' : 'light'} me-2`}
                                                onClick={() => handleRoleChange(index, 'OWNER', 'role')}>
                                                Owner
                                            </button>

                                            <button className={`btn btn-${isEditor(newUserRole[index].role) ? 'success' : 'light'} me-2`}
                                                onClick={() => handleRoleChange(index, 'EDITOR', 'role')}>
                                                Editor
                                            </button>

                                            <button className={`btn btn-${isViewer(newUserRole[index].role) ? 'success' : 'light'} me-2`}
                                                onClick={() => handleRoleChange(index, 'VIEWER', 'role')}>
                                                Viewer
                                            </button>

                                            {index > 0 && <button className='btn btn-light' onClick={() => removeRole(index)}>
                                                <i className='fe fe-trash'></i>
                                            </button>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>


                </Modal.Body>
                <Modal.Footer className='display-flex-start'>
                    <Spinner display={spinner}>
                        <button className='btn btn-dark ms-2' onClick={() => handleCreateUser('ShipperUser')}>Continue</button>
                        <button className='btn btn-link text-muted' onClick={() => { showShipperModal(false); setNewUserRole([{ id: '', name: '', role: 'VIEWER' }]) }} >Cancel</button>
                    </Spinner>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default UserOverview;

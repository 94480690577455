import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { AddressView } from '../../components/Address';
import { Card } from "../../components/Card";
import ListGroupItem from "../../components/ListGroupItem";
import Spinner from '../../components/Spinner';
import BoxStatus from '../../data/box-status.json';
import { boxStatusIcon, boxstatusBadgeColor, formatNumber } from '../../helpers';
import ReactImageGallery from 'react-image-gallery';
import { BoxStatusBadge } from '../../components/StatusBadge';
import ReactGA from "react-ga4"

const getShipmentQuery = /* GraphQL */ `
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
      id
      number
	  authCode
      shipFrom
      shipTo
      weight
      distance
      status
      services
      deliveryInstructions
      items
      billing
      attachment
	  packageCount
	  boxId
		box{
            name
			number
            status
		}
	  isGreenPhox
      shipperId
	  authCode
      createdTime

      expectedPickupTime
      actualPickupTime
      pickupLocation {
        latitude
        longitude
      }

      expectedDeliveryTime
      actualDeliveryTime
      deliveryLocation {
        latitude
        longitude
      }

      outForReturnTime
      returnedTime
      returnLocation {
        latitude
        longitude
      }

      extId
      extTrackingNumber
      extLabelUrl

      carrierId
      carrier {
        name
      }
	  route {
		routeSequence
		routeDistance
		routeDuration
	  }

      driverId
      driver {
        name
		location {
			latitude
			longitude
		}
      }
	  readyForPickupTime
      deliveryServiceId
      deliveryService {
        id
        type
        name
        pickupBy
        tatMin
        tatMax
        maxDistance
      }

      history {
        items {
          id
          createdTime
          createdAt
          status
          description
          attachment
          signature
          userId
          user {
            name
            image
          }
		  location{
			latitude
			longitude
		  }
        }
      }

      ratings {
        items {
          id
          rating
          nps
          feedback
        }
      }
    }
  }
`;

const boxStatusHistoriesByShipmentId = /* GraphQL */ `
  query BoxStatusHistoriesByShipmentId(
    $shipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBoxStatusHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    boxStatusHistoriesByShipmentId(
      shipmentId: $shipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        boxId
        userId
        user{
            name
        }
        shipmentId
        shipment{
            shipTo
            shipFrom
            shipper{
                name
                timezone{
                    id
                }
            }
        }
        status
        description
        attachment
        signature
        createdAt
        createdTime
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const BoxDetails = () => {

    const { shipmentid } = useParams()
    const [spinner, showSpinner] = useState(false);
    const [shipment, setShipment] = useState({});
    const [boxStatusHistory, setBoxStatusHistory] = useState([]);

    useEffect(() => {
        getShipment();
        ReactGA.send({
            hitType: "pageview",
            page: `/box/details/${shipmentid}`,
            title: "Green Box Details"
        })
    }, [])

    useEffect(() => getBoxStatusHistory(), [])

    const getBoxStatusHistory = () => {
        showSpinner(true)
        API.graphql({ query: boxStatusHistoriesByShipmentId, variables: { shipmentId: shipmentid } }).then((response) => {
            setBoxStatusHistory(response?.data?.boxStatusHistoriesByShipmentId?.items)
        }).catch((error) => {
            console.error(error)
        }).finally(() => showSpinner(false))
    }

    function getShipment() {
        showSpinner(true)
        API.graphql({ query: getShipmentQuery, variables: { id: shipmentid } }).then((response) => {
            let data = response?.data?.getShipment
            data.shipFrom = JSON.parse(data?.shipFrom)
            data.shipTo = JSON.parse(data?.shipTo)
            setShipment(data)
            showSpinner(false)
            return data;
        }).catch((error) => {
            console.error(error)
            toast.error('Opps, something went wrong')
            showSpinner(false)
        })
    }

    const BoxCard = () => {
        let expectedReturnTime;
        let boxReturnedAt;
        boxStatusHistory?.map((history) => {
            expectedReturnTime = moment.unix(shipment?.expectedDeliveryTime).tz(history?.shipment?.shipper?.timezone?.id).add(1, 'day').format('h:mm a z, MMM DD, YYYY')
            if (history.status === 'RETURNED') {
                boxReturnedAt = moment.unix(history?.createdTime).tz(history?.shipment?.shipper?.timezone?.id).format('h:mm a z, MMM DD, YYYY');
            }
        })
        return (
            <>
                <Card title="Box Details" bodyPadding="py-0">
                    <ListGroup className="list-group-flush">
                        <ListGroupItem name="Box Number" value={shipment?.box?.number || shipment?.box?.name} />
                        <ListGroupItem name="Box Status" value={<BoxStatusBadge status={shipment?.box?.status} />} />
                        <ListGroupItem name="Box Expected Return" value={expectedReturnTime || '-'} />
                        <ListGroupItem name="Box Returned At" value={boxStatusHistory.some((item) => item?.status === 'RETURNED') ? boxReturnedAt : '-'} />
                    </ListGroup>
                </Card>
            </>
        )
    }

    const BoxPageHeader = () => {
        return (

            <nav className="header mb-0">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center">
                            <Col className="d-flex">
                                <div>
                                    <Link to={`/shipment/${shipment?.id}`}>
                                        <h1 className="mb-1" style={{ fontWeight: '100' }} title='go to shipment details'>
                                            {`#${shipment?.number}`}
                                        </h1>
                                    </Link>
                                    <h2 className="text-muted mb-1">
                                        {shipment?.shipTo?.name || "-"}
                                    </h2>
                                </div>
                                <div className="align-item-centers">

                                    {shipment?.isGreenPhox && < img src="/img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'50px'} width={'50px'} />}
                                </div>
                            </Col>
                            <Col>
                                <div className="small text-muted">
                                    <AddressView address={shipment?.shipTo?.address} />
                                </div>
                            </Col>
                            <Col>
                                <div className="small text-muted">
                                    {shipment?.shipTo?.email && (
                                        <div>
                                            <i className="fe fe-mail" /> {shipment?.shipTo?.email}
                                        </div>
                                    )}
                                    {shipment?.shipTo?.phone?.length > 10 && (
                                        <div>
                                            <i className="fe fe-phone" />{" "}
                                            {formatNumber(shipment?.shipTo?.phone)}
                                        </div>
                                    )}
                                </div>
                            </Col>


                        </Row>
                    </div>
                </Container>

            </nav >
        )

    }

    const CarrierDriver = () => {

        return (
            <>
                <Card
                    title="Courier Details"
                    bodyPadding="py-0"
                >
                    <ListGroup className="list-group-flush">
                        <ListGroupItem name="Courier" value={shipment?.carrier?.name} />
                        <ListGroupItem name="Driver" value={shipment?.driver?.name || '-'} />
                    </ListGroup>
                </Card>
            </>
        )
    }

    const PODCard = () => {

        return (
            <>
                <Card title="Proof of Return" bodyPadding="py-0"   >
                    <ListGroup className="list-group-flush">
                        <ListGroupItem name="Signed By" value={shipment?.carrier?.name} />
                        {shipment?.attachment?.length > 0 && (
                            <div className="position-relative my-3">
                                <ReactImageGallery
                                    showBullets={false}
                                    showThumbnails={false}
                                    lazyLoad={true}
                                    showPlayButton={false}
                                    items={shipment?.attachment?.map((item, index) => ({
                                        original: item,
                                    }))}
                                />
                            </div>
                        )}
                    </ListGroup>
                </Card>
            </>
        )
    }

    const BoxHistory = () => {

        return (
            <>
                <Card title="Status">
                    <ListGroup className="list-group-flush list-group-activity my-n3">
                        {boxStatusHistory &&
                            boxStatusHistory?.sort((a, b) => b.createdTime - a.createdTime)?.map((item, index) => {
                                return (
                                    <ListGroup.Item key={index}>
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="avatar avatar-sm">
                                                    <div
                                                        className={`avatar-title fs-lg badge bg-${boxstatusBadgeColor(
                                                            item?.status
                                                        )} rounded-circle`}
                                                    >
                                                        <i className={boxStatusIcon(item?.status)}></i>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="activity-alignment" >
                                                {BoxStatus[item?.status]}
                                                <div className="small text-muted">
                                                    <div>{item.description}</div>
                                                    {item?.user?.name && <div className="mt-2">
                                                        <i className="fe fe-user" />{" "}
                                                        {item?.user?.name}
                                                    </div>}
                                                </div>
                                            </Col>
                                            <Col className="col-auto activity-alignment activity-margin">
                                                <div className="small ">
                                                    {moment(item?.createdAt)?.tz(item?.shipment?.shipper?.timezone?.id)?.format('h:mm a z')}
                                                </div>
                                                <div className="small text-muted ">
                                                    {moment(item?.createdAt)?.tz(item?.shipment?.shipper?.timezone?.id)?.format('MMM DD, YYYY')}
                                                </div>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </Card>


            </>
        );
    };




    return (
        <>
            <Spinner display={spinner} >
                <BoxPageHeader />
                <Container fluid className="mt-5">
                    <Row>
                        <Col>
                            <BoxCard />
                            <CarrierDriver />
                        </Col>
                        <Col><BoxHistory /></Col>
                        {/* <Col><PODCard /> </Col> */}
                    </Row>
                </Container>


            </Spinner>


        </>
    )
}

export default BoxDetails

import { API } from "aws-amplify";
import toast from "react-hot-toast";

const processShipmentBilling = /* GraphQL */ `
  mutation ProcessShipmentBilling($id: ID!) {
    processShipmentBilling(id: $id)
  }
`;

export const processBilling = async (id) => {
    try {
        await API.graphql({ query: processShipmentBilling, variables: { id: id } })
    } catch (error) {
        console.log(error)
        toast.error("Oops something went wron while calculating the billing! ")
    }
}
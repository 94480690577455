/* eslint-disable react-hooks/exhaustive-deps */

import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { storeApp, storeCarrier, storeHost, storeShipper, storeShipperGroup, storeShippers, storeUser } from './../stores/slice';
import Footer from './Footer';
import { LeftMenu, TopMenu } from './Menu';
import { useIdleTimer } from 'react-idle-timer';
import { FullStory } from '@fullstory/browser';


const AdminLayout = () => {

	const dispatch = useDispatch()
	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location.search);
	const fullscreen = searchParams.get('fullscreen');

	useEffect(() => getSessionData(), [])

	const getSessionData = () => {
		Auth.currentSession().then((session) => {
			let data = localStorage.getItem('phsp')
			if (data) {
				data = JSON.parse(data);
				dispatch(storeHost(data.HOST));
				dispatch(storeUser(data.USER));
				dispatch(storeApp(data.APP));
				dispatch(storeShipperGroup(data.SHIPPER_GROUP));
				dispatch(storeShipper(data.SHIPPER));
				dispatch(storeShippers(data.SHIPPERS));
				dispatch(storeCarrier(data.CARRIER));
				FullStory('setIdentity', {
					uid: data.USER?.id,
					properties: {
						displayName: data?.USER?.name,
						email: data?.USER?.email,
						shipper: data?.SHIPPER?.shipper?.name || data?.SHIPPER?.shipper?.alias,
						role: data?.SHIPPER?.role,
					}
				})
			}
		}).catch((error) => {
			window.location = '/auth/logout'
		})
	}

	const onIdle = () => {
		navigate('/auth/logout');
	}

	useIdleTimer({ onIdle, timeout: 900_000, throttle: 500 })

	return (
		<>
			{
				fullscreen ? <div className='main-content'>
					<Outlet />
				</div> : <>
					<LeftMenu />
					<div className='main-content'>
						<Outlet />
						<Footer />
					</div>
				</>
			}
		</>
	);
};

export default AdminLayout;

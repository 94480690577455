import { API } from 'aws-amplify'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import { TableDateFilter, TableKeywordSearch, TableShipperFilter } from '../../components/TableFilter'
import BoxStatus from '../../data/box-status.json'
import { BoxstatusButtonColor, DELAY_TIMEOUT, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers'
import ReactGA from "react-ga4"

const getShipmentBox = /* GraphQL */ `
query GetShipmentBox($id: ID!) {
  getShipmentBox(id: $id) {
    id
    number
    name
    status
    type
    size
  }
}
`;

const shipmentsByBoxId = /* GraphQL */ `
query ShipmentsByBoxId(
  $boxId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelShipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  shipmentsByBoxId(
    boxId: $boxId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      number
      batch
      authCode
      shipFrom
      shipTo
      weight
      distance
      status
      services
      deliveryInstructions
      items
      billing
      attachment
      createdTime
      readyForPickupTime
      expectedPickupTime
      actualPickupTime
      expectedDeliveryTime
      actualDeliveryTime
      outForReturnTime
      returnedTime
      extId
      extTrackingNumber
      extLabelId
      extLabelUrl
      packageCount
      isGreenPhox
      customerId
      shipperGroupId
      shipperId
      shipper{
        name
        alias
      }
      carrierId
      carrier{
        name
        alias
      }
      userId
      driverId
      deliveryServiceId
      deliveryService{
        name
      }
      boxId
      box{
        status
        updatedAt
      }
      createdAt
      updatedAt
      shipmentRouteId
      __typename
    }
    nextToken
    __typename
  }
}
`;


const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        default
        sort
        shipperId
        carrierId
        carrier{
            id
            name
            alias
            image
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const BoxShipments = () => {

    const myShipper = useSelector((state) => state.slice.SHIPPER)
    const myShippers = useSelector((state) => state.slice.SHIPPERS)
    const { id } = useParams();
    const navigate = useNavigate();

    const [BoxData, setBoxData] = useState({});
    const [boxStatus, setBoxStatus] = useState();
    const [shipments, setShipment] = useState([]);
    const [spinner, showSpinner] = useState(false);
    const [carrier, setCarrier] = useState({})
    const [carrierList, setCarrierList] = useState([])
    const [keyword, setKeyword] = useState('')
    const [facets, setFacets] = useState({});
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [dateFilters, setDateFilters] = useState({
        fromDate: moment()
            .tz(myShipper?.shipper?.timezone?.id || "America/New_York")
            .subtract(6, 'days').startOf('day').unix(),
        toDate: moment()
            .tz(myShipper?.shipper?.timezone?.id || "America/New_York")
            .endOf("day")
            .unix(),
    });

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/box/${id}`,
            title: "Green Phox Box Shipments"
        })
    }, [id])


    useEffect(() => { getBoxData(); }, [])
    useEffect(() => { getShipmentsByBoxID(); }, [BoxData, myShipper?.shipper?.id, carrier?.id, boxStatus])

    useEffect(() => {
        const delay = setTimeout(() => {
            getShipmentsByBoxID();

        }, DELAY_TIMEOUT);
        return () => clearTimeout(delay);
    }, [keyword, dateFilters]);

    const getBoxData = () => {
        showSpinner(true)
        API.graphql({ query: getShipmentBox, variables: { id } }).then((response) => {
            if (response) {
                setBoxData(response?.data?.getShipmentBox)
                showSpinner(false)
            }
        }).catch((error) => {
            console.error(error)
            toast.error('Opps,Something went werong')
            showSpinner(false)
        })
    }

    const getShipmentsByBoxID = async () => {
        try {
            showSpinner(true)
            const apiName = 'api';
            const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
            let init = {
                body: {
                    "aggs": {
                        status: {
                            terms: {
                                "field": "status.keyword",
                                "size": 100
                            }
                        }
                    },
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        },
                        {
                            "updatedAt": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            should: [],
                            must: [{
                                match: { boxId: id }
                            }]
                        }
                    }
                }
            };


            if (!keyword) {
                init.body.query.bool.must.push({
                    range: {
                        "expectedDeliveryTime": {
                            "gte": dateFilters?.fromDate,
                            "lte": dateFilters?.toDate
                        }
                    }
                })
            } else {
                if (keyword) {
                    // while searching with keyword we dont want to sort
                    delete init.body.sort

                    let fields = [
                        "shipTo.name^3",
                        "shipTo.first_name^2",
                        "shipTo.last_name",
                        "shipTo.phone",
                        "extId",
                        "items.number.keyword"
                    ]
                    if (+keyword) {
                        fields.push("number")
                    }
                    init.body.query.bool.must.push(
                        {
                            "multi_match": {
                                "query": keyword,
                                "fields": fields
                            }
                        }
                    )
                }
            }

            let numbersearch = Number(keyword)
            if (keyword && !isNaN(numbersearch)) init.body.query.bool.should.push({ match: { number: keyword } })

            if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
            else if (myShippers) {
                let shipperIdArr = []
                myShippers.forEach((item) => {
                    shipperIdArr.push(item.shipper.id)
                })
                init.body.query.bool.must.terms = { shipperId: shipperIdArr }
            }

            if (carrier?.id) init.body.query.bool.must.push({ match: { carrierId: carrier?.id } })
            const data = await API.post(apiName, path, init);

            let statusCount = {};
            data.aggregations.status.buckets?.forEach((item) => {
                statusCount[item.key] = item.doc_count
            })
            const sourceData = data?.hits?.hits?.length > 0 ? data?.hits?.hits?.map((item) => item?._source) : [];
            setShipment(sourceData);
            setFacets(facets);
            setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
            showSpinner(false);
            return sourceData;

        } catch (error) {
            console.error('ealstic error', error);
            showSpinner(false);
        }
    }


    return (
        <>
            <nav className='header mb-0'>
                <Container fluid>
                    <div className='header-body'>
                        <Row className='align-items-center'>
                            <Col className="d-flex">
                                <div>
                                    <h6 className='header-pretitle'>Box Shipment</h6>
                                    <h1 className="mb-1">
                                        {BoxData?.number || BoxData?.name}
                                    </h1>
                                    <h2 className="text-muted mb-1  ">
                                        {BoxData?.type || "-"}-{BoxData?.size || "-"}
                                    </h2>
                                </div>
                            </Col>
                            <Col className='col-auto'>
                                <Button className={BoxstatusButtonColor(BoxData?.status)}  >
                                    {BoxStatus[BoxData?.status]}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </nav>

            <Container fluid>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            <TableShipperFilter />
                            {/* {Object.keys(facets).length > 0 && <TableFacetFilter name='Courier' item={carrier} facets={facets} facetName='carrier.name' onChange={setCarrier} />} */}
                            <TableDateFilter startOf={6} timezone={myShipper?.shipper?.timezone?.id} onChange={setDateFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>

                        <Table responsive size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th className="text-center">Shipment #</th>
                                    {!myShipper?.shipper?.id && <th>Shipper</th>}
                                    <th>Recipient</th>
                                    <th>Driver / Carrier</th>
                                    <th className="text-center">Delivery Service</th>
                                    <th className="text-center">Entered At</th>
                                    <th className="text-center">Due By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipments.map((shipment) => {
                                    return (
                                        <tr
                                            key={shipment.id}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                navigate(`/box/details/${shipment?.id}`, "_self");
                                            }}
                                        >
                                            <td className="text-center">
                                                {shipment.number}
                                                {shipment.batch_number > 0 && (
                                                    <div className="small text-muted">
                                                        Batch #{shipment?.batch_number}
                                                    </div>
                                                )}
                                            </td>

                                            {!myShipper?.shipper?.id && (
                                                <td>{shipment?.shipper?.name}</td>
                                            )}

                                            <td>
                                                {shipment.shipTo?.name}
                                                <div className="small text-muted">
                                                    {shipment.shipTo?.address?.city},{" "}
                                                    {shipment.shipTo?.address?.state}
                                                </div>
                                            </td>

                                            <td>
                                                <div>{shipment.driver?.name || "Unassigned"}</div>
                                                <div className="text-small text-muted">
                                                    {shipment?.carrier?.alias}
                                                </div>
                                            </td>

                                            <td className="text-center">
                                                {shipment.deliveryService?.name}
                                            </td>

                                            <td className="text-center">
                                                {toLocalTime(
                                                    shipment?.createdTime,
                                                    shipment?.shipFrom?.timezone?.id
                                                )}
                                                <div className="small text-muted">
                                                    {toLocalDate(
                                                        shipment?.createdTime,
                                                        shipment?.shipFrom?.timezone?.id
                                                    )}
                                                </div>
                                            </td>

                                            <td className="text-center">
                                                {shipment?.expectedDeliveryTime && (
                                                    <>
                                                        {toLocalTime(
                                                            shipment?.expectedDeliveryTime,
                                                            shipment?.shipFrom?.timezone?.id
                                                        )}
                                                        <div className="small text-muted">
                                                            {toLocalDate(
                                                                shipment?.expectedDeliveryTime,
                                                                shipment?.shipFrom?.timezone?.id
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Spinner>
                </Card>



            </Container>
        </>
    )
}

export default BoxShipments

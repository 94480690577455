import React from 'react';

const Avatar = ({ className, size, alt, online, image }) => {
	let avatarClass = ['avatar']
	avatarClass.push(`avatar-${size || 'sm'}`)

	if (online) avatarClass.push('avatar-online')
	else avatarClass.push('avatar-offline')

	avatarClass.push(className);
	return (
		<div className={`${avatarClass.join(' ')}`}>
			<img className='avatar-img rounded-circle' alt={alt || 'avatar'} src={image || '/img/default-user.png'} />
		</div>
	);
};

export default Avatar;

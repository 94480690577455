import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, Form, FormGroup, Modal, Row, Table } from "react-bootstrap";
import ReactGA from "react-ga4";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { StatCard } from '../../components/Card';
import FormLabel from '../../components/FormLabel';
import PageHeader from "../../components/PageHeader";
import Spinner from "../../components/Spinner";
import { BoxStatusBadge } from '../../components/StatusBadge';
import { BoxStatusFilter, TableClearFilter, TableKeywordSearch, TableShipperFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, isViewer, toLocalDate } from '../../helpers';
import { storeShipper } from '../../stores/slice';
const _ = require("lodash");

const shipmentBoxesByShipperId = /* GraphQL */ `
  query ShipmentBoxesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentBoxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentBoxesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipments{
        items{
            id 
            status
            }
        }
      }
      nextToken
    }
  }
`;


const updateShipmentBoxMutation = /* GraphQL */ `
  mutation UpdateShipmentBox(
    $input: UpdateShipmentBoxInput!
  ) {
    updateShipmentBox(input: $input) {
      id
    }
  }
`;

const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;


const BoxManagement = () => {
    const myShipper = useSelector((state) => state.slice.SHIPPER)
    const myShippers = useSelector((state) => state.slice.SHIPPERS)
    const myUser = useSelector((state) => state.slice.USER)
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('')

    const [spinner, showSpinner] = useState(false);
    const [boxes, setBoxes] = useState([])
    const [boxStatus, setBoxStatus] = useState()

    const [boxStatusCount, setBoxStatusCount] = useState();
    const [greenPhoxEnrolledPatients, setGreenPhoxEnrolledPatinets] = useState(0)
    const [boxModal, showBoxModal] = useState(false);
    const [slackInput, setSlcakInput] = useState();
    const [boxReleaseModal, setBoxReleaseModal] = useState(false)
    const [selectedBox, setSelectedBox] = useState({});

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/green-phox",
            title: "Green Phox"
        })
    }, [])

    useEffect(() => {
        let delay;
        if (myShipper?.shipper?.name || (myShippers && myShippers?.length > 0)) {
            delay = setTimeout(() => { getBoxesByShipper(); }, DELAY_TIMEOUT)
            return () => clearTimeout(delay)
        }
    }, [myShipper, boxStatus, keyword])

    useEffect(() => {
        getGreenPhoxEnrolledpatients();
    }, [myShipper])

    const getBoxesByShipper = async () => {
        showSpinner(true);
        try {
            let filter = {}
            if (boxStatus) filter.status = { eq: boxStatus }
            if (keyword) {
                filter.or = [{ name: { contains: keyword.toUpperCase() } }, { number: { eq: keyword } }]
                if (!+keyword) filter.or.pop()
            }

            const apiName = 'api';
            const path = `/search/shipment-box?size=${PAGE_SIZE}`;

            let init = {
                body: {
                    "track_total_hits": true,
                    aggs: {
                        shipper_group: {
                            terms: {
                                "field": "shipperGroup.name.keyword",
                                "size": 1000
                            }
                        },
                        shipper: {
                            terms: {
                                "field": "shipper.name.keyword",
                                "size": 1000
                            }
                        },
                        status: {
                            terms: {
                                "field": "status.keyword",
                                "size": 1000
                            }
                        }
                    },
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        },
                        {
                            "createdAt": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            "must": [],
                            "filter": []
                        }
                    }
                }
            };

            if (keyword) {
                let fields = [
                    "name",
                ]
                if (+keyword) fields.push("number")
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword?.trim(),
                            "fields": fields
                        }
                    }
                )

            }

            if (boxStatus) init.body.query.bool.filter.push({ "term": { "status.keyword": boxStatus } })
            if (myShipper?.shipper?.name) init.body.query.bool.filter.push({ "term": { "shipper.name.keyword": myShipper?.shipper.name } })
            else if (myShippers) {
                let shipperIdArr = []
                myShippers.forEach((item) => {
                    shipperIdArr.push(item.shipper.id)
                })
                init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
            }
            const elasticData = await API.post(apiName, path, init);
            if (elasticData) {
                let boxElasticData = elasticData?.hits?.hits.map((item) => item._source)
                let total = elasticData?.hits?.total?.value;
                // get total deliveries
                if (myShipper?.shipper?.id) {
                    let filter = {}
                    if (boxStatus) filter.status = { eq: boxStatus }
                    if (keyword) {
                        filter.or = [{ name: { contains: keyword.toUpperCase() } }, { number: { eq: keyword } }]
                        if (!+keyword) filter.or.pop()
                    }
                    const { data } = await API.graphql({ query: shipmentBoxesByShipperId, variables: { shipperId: myShipper?.shipper?.id, filter } })
                    let boxData = data.shipmentBoxesByShipperId.items

                    for (let i = 0; i < boxElasticData.length; i++) {
                        let id = boxElasticData[i].id
                        let shipments = boxData.find(item => item.id === id).shipments.items.length;
                        boxElasticData[i].shipment_count = shipments
                    }
                }
                setBoxes(boxElasticData)
                const boxStatusCount = _.groupBy(boxElasticData, 'status');
                setBoxStatusCount({ ...boxStatusCount, total });
                showSpinner(false);
            }
        } catch (error) {
            console.error(error)
            showSpinner(false);
            toast.error('Opps, Something went wrong')
        }
    }

    const getGreenPhoxEnrolledpatients = async () => {
        showSpinner(true);
        try {
            const apiName = 'api';
            const path = `/search/customer?size=10000`;

            let init = {
                body: {
                    sort: [
                        { "_score": { "order": "desc" } },
                        { "updatedAt": { "order": "desc", "unmapped_type": "date" } }
                    ],
                    query: {
                        bool: {
                            should: [],
                            must: [
                                {
                                    match: {
                                        isGreenPhox: true
                                    }
                                }, {
                                    match: {
                                        active: true
                                    }
                                }
                            ]
                        }
                    }
                }
            }

            if (keyword) {
                delete init.body.sort
                let fields = [
                    "extId",
                    "firstName",
                    "name",
                    "lastName",
                    "phone",
                    "email"
                ]
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword,
                            "fields": fields
                        }
                    }
                )
            }

            if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
            else if (myShippers) {
                let shipperIdArr = []
                myShippers.forEach((item) => {
                    shipperIdArr.push(item.shipper.id)
                })
                init.body.query.bool.must.push({ terms: { shipperId: shipperIdArr } })
            }


            let data = await API.post(apiName, path, init);
            if (data) setGreenPhoxEnrolledPatinets(data?.hits?.hits?.length)
        } catch (error) {
            showSpinner(false);
            toast.error('Opps, Something went wrong')
            console.error('error from  elastic search cutomer', error)
        }
    }


    const openBoxReleaseModal = (box) => {
        setSelectedBox(box)
        setBoxReleaseModal(true)
    }

    const releaseShipmentBox = (id, number, name) => {
        let message = `Shipper name: ${myShipper?.shipper?.name || '-'}\n`
        message += `Released box: #${number || name || '-'}\n`;
        message += `Status: Released \n`;
        message += `Description: Released the Box`;
        message = message?.replace(/(\w+) : /g, '$1: ');
        const input = { channel: 'critical-alerts', message, user: myUser?.name };
        showSpinner(true)
        setBoxReleaseModal(false)
        API.graphql({ query: updateShipmentBoxMutation, variables: { input: { id, shipperId: null, shipperGroupId: null, shipmentId: null, status: 'RETIRED', retiredAt: moment().unix() } } }).then(async (response) => {
            if (response) {
                getBoxesByShipper()
                await API.graphql({ query: sendSlackAlert, variables: { input } })
                toast.success('Box has been released')
                setSelectedBox({})
            }
        }).catch((error) => {
            toast.error(error);
        }).finally(() => {
            showSpinner(false)
            setBoxReleaseModal(false)
        })
    }

    const sendRequestOnSlcak = () => {
        showSpinner(true)
        let message = `Shipper name: ${myShipper?.shipper?.name || '-'}\n`
        message += `Total requested boxes: ${slackInput?.number || '-'}\n`;
        message += `Description: ${slackInput?.message?.replace(/\n/g, '\n\t\t\t\t') || '-'}\n`;
        message = message?.replace(/(\w+) : /g, '$1: ');

        const input = { channel: 'critical-alerts', message, user: myUser?.name };
        API.graphql({ query: sendSlackAlert, variables: { input } }).then((response) => {
            toast.success('Request has been sent')
            showBoxModal(false)
            setSlcakInput({})
        }).catch((error) => {
            console.error(error);
            toast.error("Opps,something went werong ")
        }).finally(() => showSpinner(false))
    }

    const UpdateBoxStatus = (boxId) => {
        swal({
            text: `Mark as Available?`,
            buttons: ['No', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                showSpinner(true)
                let boxInput = {
                    id: boxId,
                    status: 'AVAILABLE',
                    userId: null,
                    carrierId: null,
                    shipmentId: null
                }

                API.graphql({ query: updateShipmentBoxMutation, variables: { input: boxInput } }).then((response) => {
                    toast.success('Box status has been updated')
                    getBoxesByShipper()
                }).catch((error) => {
                    toast.error('Opps,something went wrong');
                    console.error(error);
                }).finally(() => { showSpinner(false); })

            }
        });
    }

    const clearFilters = () => {
        dispatch(storeShipper(false))
        setBoxStatus(null)
    }
    return (
        <>
            <PageHeader name="GreenPhox">
                {myShipper?.role !== "VIEWER" && (
                    <Button className="btn btn-dark" onClick={() => showBoxModal(true)} >
                        Request Box
                    </Button>
                )}
            </PageHeader>

            <Container fluid>
                <Row>
                    <StatCard title="GreenPhox Enrolled" value={greenPhoxEnrolledPatients || 0} icon="box" />
                    <StatCard title="Total Boxes" value={boxStatusCount?.total || 0} icon="package" />
                    <StatCard title="Available" value={boxStatusCount?.AVAILABLE?.length || 0} icon="check-circle" />
                    <StatCard title="In-Use" value={boxStatusCount?.IN_USE?.length || 0} icon="repeat" />
                    <StatCard title="OFR / Returned" value={(boxStatusCount?.OUT_FOR_RETURN?.length + boxStatusCount?.RETURNED?.length || boxStatusCount?.RETURNED?.length || boxStatusCount?.OUT_FOR_RETURN?.length) || 0} icon="repeat" />
                </Row>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            <TableShipperFilter />
                            <BoxStatusFilter onChange={setBoxStatus} value={boxStatus} />
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>
                        <Table responsive size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th className="text-center">Box Number #</th>
                                    {!myShipper?.shipper?.name && <th className="text-center">Shipper</th>}
                                    <th className="text-center">Assigned At</th>
                                    {myShipper?.shipper?.id && <th className="text-center">Total Deliveries</th>}
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {boxes?.sort((a, b) => b?.updatedAt.localeCompare(a?.updatedAt, undefined, { sensitivity: 'accent' }))?.map((box, index) => {
                                    let totalBoxDeliveries = 0;
                                    box?.shipment?.items?.map((item) => {
                                        if (item?.status === 'DELIVERED') {
                                            totalBoxDeliveries++
                                        }
                                    })
                                    return <tr key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { !isViewer(myShipper?.role) && navigate(`/box/${box?.id}`) }}>
                                        <td className="text-center">{box?.number || box?.name} </td>
                                        {!myShipper?.shipper?.name && <td className="text-center">{box?.shipper?.alias || box?.shipper?.name} </td>}
                                        <td className="text-center">{toLocalDate(box?.assignedAt, myShipper?.shipper?.timezone?.id)}</td>
                                        {myShipper?.shipper?.id && <td className="text-center">
                                            {box?.shipment_count}
                                        </td>}

                                        <td className='text-center' onClick={(e) => e.stopPropagation()}>
                                            <BoxStatusBadge status={box?.status} />
                                        </td>
                                        <td className='text-center' onClick={(e) => e.stopPropagation()}>
                                            <div div className={`dropdown`}>
                                                <a className={`dropdown-ellipses dropdown-toggle`} role="button" data-toggle='dropdown' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fe fe-more-vertical"></i>
                                                </a>
                                                <div style={{ overflowY: "initial" }} className="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end" >
                                                    {<button className={`dropdown-item ${['IN_USE', 'OUT_FOR_RETURN', 'DAMAGED', 'AVAILABLE'].includes(box?.status) && "disabled"} `} onClick={() => UpdateBoxStatus(box?.id)}>Make Available</button>}
                                                    {<button className={`dropdown-item ${['IN_USE', 'OUT_FOR_RETURN'].includes(box?.status) && "disabled"}`} onClick={() => { openBoxReleaseModal(box) }}>Retire</button>}
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Spinner>
                </Card>
            </Container >

            <Modal show={boxModal} onHide={() => boxModal(false)} aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header>
                    <Modal.Title className="modal-title">
                        Request Boxes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <FormLabel required={true} > how many boxes do you want? </FormLabel>
                        <Form.Control type='number' placeholder='e.g. 10' defaultValue={slackInput?.number || ''} onChange={(e) => setSlcakInput({ ...slackInput, number: e.target.value })} onWheel={(e) => e.target.blur()} />
                    </FormGroup>
                    <FormGroup className='mt-4'>
                        <FormLabel> Description </FormLabel>
                        <div>
                            <textarea className='form-control' type='text' placeholder='e.g. we want more boxes ' defaultValue={slackInput?.message || ''} onChange={(e) => setSlcakInput({ ...slackInput, message: e.target.value })} ></textarea>
                        </div>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer className="display-flex-start">
                    <Spinner display={spinner}>
                        <button className="btn btn-dark ms-2" onClick={() => sendRequestOnSlcak()}>Send Request </button>
                        <button className="btn btn-link text-muted" onClick={() => { showBoxModal(false); setSlcakInput({}) }}>Cancel</button>
                    </Spinner>
                </Modal.Footer>
            </Modal >

            <Modal show={boxReleaseModal} onHide={() => setBoxReleaseModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered  >
                <Modal.Body>
                    <div>
                        <div className='d-flex justify-content-center' >
                            <i className='fe fe-alert-circle text-warning' style={{ fontSize: "80px" }}></i>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h4 className='text-muted'>Are you sure you want to retire PhoxBox {selectedBox?.name || selectedBox?.number}?</h4>
                        </div>
                        <div className='d-flex justify-content-end mt-4'>
                            <button className='btn btn-md btn-light me-2'
                                onClick={() => { setBoxReleaseModal(false) }}> No </button>
                            <button className='btn btn-md btn-danger'
                                onClick={() => releaseShipmentBox(selectedBox?.id, selectedBox?.number, selectedBox?.name)}> Yes </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BoxManagement

/* eslint-disable react-hooks/exhaustive-deps */

import { MapView } from '@aws-amplify/ui-react-geo';
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import awsconfig from "../../aws-exports";
import { ElasticFacetFilter } from '../../components/ElasticFilter';
import { DriverMarker, ShipmentMarker, ShipperMarker } from '../../components/MapMarker';
import Spinner from '../../components/Spinner';
import { MapToggleButton, StatusFilter, TableClearFilter, TableHomeButton, TableShipperFilter } from '../../components/TableFilter';
import { PAGE_TITLE } from '../../helpers';
import ReactGA from "react-ga4";
import { UserCarrierFilter } from '../../components/FilterWithIds';


const onShipmentUpdate = /* GraphQL */ `
  subscription ShipmentSubscription ($filter: ModelSubscriptionShipmentFilterInput) {
    onUpdateShipment(filter: $filter) {
      id
      status
	}
  }
`;

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;
const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        default
        sort
        shipperId
        carrierId
        carrier{
            id
            name
            alias
            image
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const usersByCarrierId = /* GraphQL */ `
  query UsersByCarrierId (
    $carrierId: ID!
    $nextToken: String
  ) {
    carrierUsersByCarrierId (
      carrierId: $carrierId
      nextToken: $nextToken
    ) {
      items {
      user {
        location {
          latitude
          longitude
          updatedAt
        }
        name
        id
      }
    }
    }
  }
`;

const LiveTracker = () => {
	const myShipper = useSelector((state) => state.slice.SHIPPER)
	const mapRef = useRef();
	const [mapState, setMapState] = useState({ latitude: 37.7061332, longitude: -101.4802194, zoom: 4 })
	const [mapStyle, setMapStyle] = useState(awsconfig.geo.amazon_location_service.maps.default);

	const [carrier, setCarrier] = useState({})
	const [carrierList, setCarrierList] = useState([])
	const [spinner, showSpinner] = useState(true);
	const [shipments, setShipments] = useState([])
	const [facets, setFacets] = useState({})

	const [drivers, setDrivers] = useState([])
	const [tempDrivers, setTempDrivers] = useState([])
	const [driver, setDriver] = useState()

	const [shipper, setShipper] = useState()
	const [status, setStatus] = useState()

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/map",
		})
		document.title = `Live Tracker ${PAGE_TITLE}`;
	}, [])


	useEffect(() => {
		getShipments();
		API.graphql({ query: onShipmentUpdate }).subscribe({
			next: (response) => {
				try {
					const shipment = response?.value?.data?.onUpdateShipment;
					if (shipment) {
						let myShipments = [...shipments];
						const index = myShipments?.findIndex(x => x.id === shipment?.id);
						if (index >= 0) {
							myShipments[index].status = shipment.status;
							setShipments(myShipments);
						}
					}
				} catch (error) {
					console.error('An error occurred during shipment update:', error);
				}
			},
			error: (error) => console.error('onShipmentUpdate', error),
		});

		API.graphql({ query: onUserLocationUpdate }).subscribe({
			next: (response) => {
				try {
					const driver = response?.value?.data?.onUpdateUserLocation;
					if (driver?.latitude && driver?.longitude && driver?.id) {
						setDrivers(prevDrivers => {
							const updatedDrivers = [...prevDrivers];
							const index = updatedDrivers.findIndex(x => x.id === driver.id);
							if (index >= 0) updatedDrivers[index] = { ...updatedDrivers[index], location: { latitude: driver.latitude, longitude: driver.longitude } }
							return updatedDrivers;
						});
					}
				} catch (error) {
					console.error('An error occurred during user update:', error);
				}
			},
			error: (error) => {
				console.error('Subscription error:', error);
			}
		});
	}, []);


	useEffect(() => getShipments(), [driver, status, myShipper, carrier]);

	const clearFilters = () => {
		setDriver();
		setStatus();
		setCarrier(null);
		setTempDrivers([])
		mapRef.current?.flyTo({ center: [mapState.longitude, mapState.latitude], zoom: mapState.zoom })
	};

	const getServices = () => {
		setCarrier(null)
		myShipper?.shipper?.id && API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.shipper?.id } }).then((response) => {
			const deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));

			let carriers = [];
			deliveryServices.forEach((item) => {
				if (!carriers.find(x => x?.id === item?.carrier?.id))
					carriers.push(item.carrier)
			});

			carriers = carriers.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
			setCarrierList(carriers)
		})
	}

	const getShipments = () => {
		if (!myShipper?.shipper?.id) return;
		const { location } = myShipper.shipper.address;
		setShipper({ name: myShipper.shipper.name, address: { location: location } });
		setMapState({ latitude: location.latitude, longitude: location.longitude, zoom: 8 });

		const apiName = 'api';
		const path = `/search/shipment?size=${100}`;
		let init = {
			body: {
				"aggs": {
					status: {
						terms: {
							"field": "status.keyword",
							"size": 100
						}
					},
					driver: {
						terms: {
							"field": "driver.name.keyword",
							"size": 100
						}
					}
				},
				sort: [
					{
						"updatedAt": {
							"order": "desc",
							"unmapped_type": "date"
						}
					}
				],
				query: {
					bool: {
						should: [],
						must: [{
							range: {
								"expectedDeliveryTime": {
									"gte": moment().startOf('day').unix(),
									"lte": moment().endOf('day').unix()
								}
							}
						}, { match: { shipperId: myShipper?.shipper?.id } }
						],
					}
				}
			}
		};
		if (status) init.body.query.bool.must.push({ match: { status: status } });
		if (driver) init.body.query.bool.must.push({ match: { "driver.name": driver } });
		if (carrier) init.body.query.bool.must.push({ match: { "carrierId": carrier?.id } });

		API.post(apiName, path, init).then(data => {
			const shippers = [];
			const driverList = [];

			for (const shipment of data.hits.hits) {
				if (shipment?.shipper?.id && !shippers.some(x => x.id === shipment.shipper.id)) {
					shipment.shipper.address = shipment.shipFrom.address;
					shippers.push(shipment.shipper);
				}

				if (shipment?.driverId) {
					const index = driverList.findIndex(x => x.id === shipment.driverId)
					if (index < 0) {
						driverList.push({
							id: shipment.driverId,
							name: shipment.driver?.name,
							image: shipment.driver?.image,
							location: shipment.driver?.location
						})
					}
				}
			}
			const sourceData = data?.hits?.hits?.length > 0 ? data?.hits?.hits?.map((item) => item?._source) : [];
			setShipments(sourceData);
			setFacets(data.aggregations);
		}).catch(e => console.log(e)).finally(() => showSpinner(false));
	};

	async function getDrivers(carrierId) {
		try {
			let res = await API.graphql({ query: usersByCarrierId, variables: { carrierId } })
			const carrierDrivers = res?.data?.carrierUsersByCarrierId?.items;
			let data = {}
			for (let item of carrierDrivers) {
				if (item.user) data[item.user.id] = item.user
			}
			setDrivers(Object.values(data))
			setTempDrivers(Object.values(data))
		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		const { latitude, longitude, zoom } = mapState;
		if (driver) {
			const index = drivers.findIndex(x => x.name === driver);
			if (index >= 0) {
				drivers[index]?.location && mapRef.current?.flyTo({ center: [drivers[index].location.longitude, drivers[index].location.latitude], zoom: 10 });
			}
		} else {
			mapRef.current?.flyTo({ center: [longitude, latitude], zoom });
		}
	}, [driver, mapState])


	useEffect(() => {
		getServices()
	}, [myShipper])

	useEffect(() => {
		if (carrier?.id) getDrivers(carrier?.id)
	}, [carrier?.id])

	useEffect(() => {
		if (driver) {
			let filterDriver = drivers.find(item => item.name === driver)
			if (filterDriver) setDrivers([filterDriver])
		}
		if (driver == null) setDrivers(tempDrivers)
	}, [driver])

	return (
		<>
			<Spinner display={spinner}>
				<MapView ref={mapRef} initialViewState={mapState} mapStyle={mapStyle}>
					{
						shipments && shipments.map((shipment, index) => <ShipmentMarker key={index} shipment={shipment} showHyperLink={true} />)
					}
					{
						shipper && <ShipperMarker key='shipper' shipper={shipper} />
					}
					{
						drivers && drivers.map((driver, index) => <DriverMarker key={index} driver={driver} />)
					}
				</MapView>

				<div style={{ position: 'fixed', top: 10, left: 20, zIndex: 2 }}>
					<Card.Body>
						<Row>
							<TableHomeButton dark={true} />
							<TableShipperFilter dark={true} />
							<UserCarrierFilter onChange={setCarrier} value={carrier} carrierList={carrierList} />
							{/* <TableStatusFilter name='Status' item={status} facets={facets} facetName='status' onChange={setStatus} dark={true} /> */}
							{/* <ElasticFacetFilter name="Status" item={status} facets={facets} facetName="status" onChange={setStatus} /> */}
							<StatusFilter onChange={setStatus} value={status} />
							{carrier && <ElasticFacetFilter name="Driver" item={driver} facets={facets} facetName="driver" onChange={setDriver} />}
							<TableClearFilter onClick={clearFilters} dark={true} />
						</Row>
					</Card.Body>
				</div>
				<div className="" style={{ position: 'fixed', top: '90vh', right: '25px', zIndex: 2 }}>
					<MapToggleButton mapStyle={mapStyle} setMapStyle={setMapStyle} />
				</div>
			</Spinner>
		</>
	)
}

export default LiveTracker;

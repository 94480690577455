import { MapView } from '@aws-amplify/ui-react-geo';
import React from 'react';
import { Layer, Source } from 'react-map-gl';

const HeatMap = ({ data }) => {
	const mapState = { latitude: 37.7061332, longitude: -101.4802194, zoom: 4 }
	const heatMapData = {
		type: 'FeatureCollection',
		features: data.map((item) => {
			return {
				type: 'Feature',
				geometry: { type: 'Point', coordinates: [item.longitude, item.latitude], },
				properties: {},
			}
		}),
	};

	return (
		<div>
			<div className='phox-font'>
				<MapView initialViewState={mapState} style={{ position: 'relative', width: '100%', height: '400px' }}>
					{
						data && (
							<Source type='geojson' data={heatMapData}>
								<Layer type='heatmap' source='geojson'
									paint={
										{
											'heatmap-weight': [
												'interpolate',
												['linear'],
												['get', 'mag'],
												0, 0, 6, 1,
											],
											'heatmap-intensity': 1,
											'heatmap-color': [
												'interpolate',
												['linear'],
												['heatmap-density'],
												0, 'rgba(0, 0, 0, 0)',
												0.3, '#CACE17',
												0.6, '#E16519',
												1, '#CA0300'
											],
											'heatmap-radius': 20,
											'heatmap-opacity': 0.6,
											'heatmap-opacity-transition': { duration: 300 },
											'heatmap-intensity-transition': { duration: 300 },
											'heatmap-radius-transition': { duration: 300 },
										}
									}
								/>
							</Source>
						)}
				</MapView>
			</div>
		</div >
	);
};

export default HeatMap;

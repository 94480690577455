import ChnageLog from "../data/change-log.json"


const MarkdownPage = () => {
  return (
    <div style={{ padding: '20px', minHeight: "85vh" }} >

      {

        ChnageLog?.map((release, index) => (
          <div key={index}>
            <h2>
              <span className="font-weight-bold" style={{ fontWeight: "900" }} >{release.version}</span>
              <span style={{ fontWeight: "lighter" }}>
                - {release?.date}
              </span>
            </h2>
            <ul>
              {Object.values(release.points).map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        ))

      }

    </div >
  );
};

export default MarkdownPage;

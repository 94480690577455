import React from 'react';
import Form from 'react-bootstrap/Form';

const FormLabel = ({ children, required }) => {
	return (
		<Form.Label className='mb-1'>
			{children}
			{required === true && <span className='text-danger'> *</span>}
		</Form.Label>
	);
};

export default FormLabel;
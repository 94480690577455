import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import { AddressField } from '../../components/Address';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { isValidEmail, isValidPhone } from '../../helpers';

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
	  firstName
	  lastName
	  active
      alias
      company
      address
	  {
		name
        address1
        address2
        address3
        city
        state
        country
		postalCode
		location {
			latitude
			longitude
		  }
	  }
	  extId
      phone
      email
      isGreenPhox
      shipperId
      shipperGroupId
	  deliveryInstructions
    }
  }
`;

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      name
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      name
    }
  }
`;

const PatientEditor = () => {

	const navigate = useNavigate()
	const myShipper = useSelector((state) => state.slice.SHIPPER)
	const { id } = useParams();
	const [errors, setErrors] = useState();
	const [spinner, showSpinner] = useState(false);
	const [customer, setCustomer] = useState({
		id: uniqid(),
		firstName: '',
		lastName: '',
		alias: '',
		company: '',
		deliveryInstructions: '',
		address: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			postalCode: '',
			location: {
				latitude: '',
				longitude: ''
			},
		},
		isGreenPhox: false,
		active: true,
		shipperId: myShipper?.shipper?.id,
		shipperGroupId: myShipper?.shipper?.shipperGroupId
	})

	const getCustomerById = (id) => {
		showSpinner(true)
		API.graphql({ query: getCustomer, variables: { id: id } })
			.then((response) => {
				let customer = response?.data?.getCustomer
				if (!customer?.firstName) customer.firstName = customer?.name
				setCustomer(customer)
			})
			.catch((error) => console.error(error))
			.finally(() => showSpinner(false));
	}

	useEffect(() => {
		if (id) {
			getCustomerById(id)
			ReactGA.send({
				hitType: "pageview",
				page: `${id ? `/patient/edit/${id}` : "/patient/create"}`,
			})
		}
	}, [id])

	const isFormValid = async () => {
		const error = {};
		showSpinner(true);
		if (!customer?.firstName?.trim()) error['first_name'] = 'First Name is required'
		if (!isValidEmail(customer?.email?.trim())) error['email'] = 'Please enter a valid email'
		if (!isValidPhone(customer?.phone?.trim()) || !customer?.phone?.trim()) error['phone'] = 'Please enter a valid phone number';
		if (!customer?.address?.address1?.trim()) error['address1'] = 'Address Line 1 is required';
		if (!customer?.address?.postalCode?.trim() && !error['address1']) error['address1'] = 'please enter the address with the postal code';

		if (!id && customer?.extId) {
			let init = {
				body: {
					query: {
						bool: {
							must: [
								{ match: { shipperGroupId: myShipper?.shipper?.shipperGroupId } },
								{ match: { extId: customer?.extId } }
							]
						}
					}
				}
			}
			const { hits } = await API.post("api", "/search/customer", init)
			if (hits?.hits.length > 0) { error['extId'] = 'User already exsits with the same MRN number, Please enter another number' }
		}

		setErrors(error)
		showSpinner(false);
		return Object.keys(error).length === 0;
	};

	const handleChange = async (e) => setCustomer((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
	const handleCheckChange = async (e) => setCustomer((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
	const handlePhoneChange = (phone) => setCustomer((prevState) => ({ ...prevState, phone: `+${phone}` }));
	const handleAddress1Change = (address1) => setCustomer((prevState) => ({ ...prevState, address: { ...prevState.address, address1: address1 } }));
	const handleAddress2Change = (e) => setCustomer((prevState) => ({ ...prevState, address: { ...prevState.address, address2: e.target.value } }));

	const handleAddressSelect = async ({ value }) => {
		const value_address = value?.address
		if (!value_address?.houseNumber && !value_address?.street) return toast.error("Please enter a valid address")
		let address1 = `${value_address?.houseNumber} ${value_address?.street}`;
		let city = value_address?.city;
		let state = value_address?.stateCode;
		let postalCode = value_address?.postalCode.split("-")[0];
		let address2 = ''

		let location = {};
		if (value?.position.lat && value?.position.lng) {
			location.latitude = value?.position.lat;
			location.longitude = value?.position.lng;
		}

		setCustomer({
			...customer,
			address: {
				address1,
				address2,
				postalCode,
				city,
				state,
				location,
			},
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (await isFormValid()) {
			if (id) customer.id = id;
			customer.shipperId = myShipper?.shipper?.id;
			customer.shipperGroupId = myShipper?.shipper?.shipperGroupId;
			customer.name = (customer.firstName && customer.lastName) ? customer.firstName + " " + customer.lastName : customer.firstName ? customer.firstName : customer?.name
			showSpinner(true);
			const query = id ? updateCustomer : createCustomer;
			API.graphql({ query: query, variables: { input: customer } })
				.then((response) => {
					setTimeout(() => {
						if (id) toast.success(`${customer?.firstName} has been updated.`)
						else toast.success(`${customer?.firstName} has been added.`)
						navigate(`/patient`);
						showSpinner(false)
					}, 1000)
				})
				.catch((error) => {
					if (error.errors[0].message === "Variable 'phone' has an invalid value.") {
						let newError = { phone: "Pleae enter a valid phone number" }
						setErrors(newError);
						showSpinner(false)
					} else {
						console.error(error)
						toast.error('Opps, something went wrong')
						showSpinner(false)
					}
				})

		}
	}

	return (
		<>
			<PageHeader name={id ? 'Edit Patient' : 'New Patient'} />
			<Spinner display={spinner}>
				<Container>
					<Form onSubmit={handleSubmit}>
						<Row>
							<Col lg={6}>
								<Form.Group>
									<FormLabel required={true}> First Name</FormLabel>
									<Form.Control type='text' name='firstName' placeholder='e.g Jone' value={customer?.firstName || ''} onChange={handleChange} isInvalid={!!errors?.first_name} />
									<Form.Control.Feedback type='invalid'>{errors?.first_name}</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group>
									<FormLabel > Last Name</FormLabel>
									<Form.Control type='text' name='lastName' placeholder='e.g Doe' value={customer?.lastName || ''} onChange={handleChange} />
								</Form.Group>
							</Col>
						</Row>

						<Form.Group className='mt-4'>
							<FormLabel>Alias</FormLabel>
							<Form.Control type='text' name='alias' placeholder='e.g. Jone' value={customer?.alias || ''} onChange={handleChange} isInvalid={!!errors?.alias} />
							<Form.Control.Feedback type='invalid'>{errors?.alias}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Company</FormLabel>
							<Form.Control type='text' name='company' placeholder='e.g. PhoxHealth Inc' value={customer?.company || ''} onChange={handleChange} isInvalid={!!errors?.company} />
							<Form.Control.Feedback type='invalid'>{errors?.company}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Delivery Instructions</FormLabel>
							<Form.Control type='text' name='deliveryInstructions' placeholder='e.g. gate code #1234, leave at back door, or knock twice' value={customer?.deliveryInstructions || ''} onChange={handleChange} />
						</Form.Group>
						<Form.Group className='mt-4'>
							<FormLabel>Medical Record Number (MRN)</FormLabel>
							<Form.Control type='text' name='extId' placeholder='e.g. 3247t3284-sZfs322' value={customer?.extId || ''} onChange={handleChange} isInvalid={!!errors?.extId} />
							<Form.Control.Feedback type='invalid'>{errors?.extId}</Form.Control.Feedback>
						</Form.Group>
						<Row>
							<Col lg={6}>
								<Form.Group className='mt-4'>
									<FormLabel>Email</FormLabel>
									<Form.Control type='email' name='email' placeholder='e.g. john@pharmacy.com' value={customer?.email || ''} onChange={handleChange} isInvalid={!!errors?.email} />
									<Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group className='mt-4'>
									<FormLabel required={true}>Phone </FormLabel>
									<Form.Control type='phone' name='phone' className='d-none' placeholder='e.g. 998-776-5543' value={customer?.phone || ''} onChange={handleChange} isInvalid={!!errors?.phone} />
									<PhoneInput country='us' onlyCountries={['us']} value={customer?.phone} placeholder='e.g. 998-776-5543' onChange={handlePhoneChange} isInvalid={!!errors?.phone} />
									<Form.Control.Feedback type='invalid'>{errors?.phone}</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col lg={6}>
								<Form.Group>
									<FormLabel required={true}>Address Line 1 </FormLabel>
									<AddressField name='address.address1' value={customer?.address?.address1}
										error={errors?.address1} isInvalid={!!errors?.address1}
										onChange={handleAddressSelect} />
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group>
									<FormLabel>Address Line 2</FormLabel>
									<Form.Control type='text' name='address2' placeholder='e.g. Suite 100' value={customer?.address?.address2 || ''} onChange={handleAddress2Change} />
								</Form.Group>
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col lg={4}>
								<Form.Group>
									<FormLabel required={true}>City</FormLabel>
									<Form.Control type='text' name='city' placeholder='e.g. Seattle' value={customer?.address?.city || ''} onChange={handleChange} disabled />
								</Form.Group>
							</Col>
							<Col lg={4}>
								<Form.Group>
									<FormLabel required={true}>State</FormLabel>
									<Form.Control type='text' name='state' placeholder='e.g. WA' value={customer?.address?.state || ''} onChange={handleChange} disabled />
								</Form.Group>
							</Col>
							<Col lg={4}>
								<Form.Group>
									<FormLabel required={true}>Postal Code</FormLabel>
									<Form.Control type='text' name='postalCode' placeholder='e.g. 98105' value={customer?.address?.postalCode} onChange={handleChange} disabled />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<Form.Group className='mt-4'>
									<FormLabel>Patient Status</FormLabel>
									<small className='form-text text-muted'>Inactivate a patient’s chart to hide them from future shipments. You can reactivate charts at anytime.</small>
									<div className='row'>
										<div className='col-auto'>
											<div className='form-check form-switch'>
												<input className='form-check-input' type='checkbox' checked={customer?.active} name='active' onChange={handleCheckChange} />
											</div>
										</div>
										<div className='col ms-n2'>
											{customer?.active ?
												<small className='text-success h5'>Active Chart</small> :
												<small className='text-danger h5'>Inactive Chart</small>
											}
										</div>
									</div>
								</Form.Group>
							</Col>

							<Col lg={6}>
								<Form.Group className='mt-4'>
									<FormLabel>GreenPhox Status</FormLabel>
									<small className='form-text text-muted'>
										Enroll patient into GreenPhox’s package recovery and reuse program. Patient will receive GreenPhox messaging for their delivery notifications.
									</small>
									<div className='row'>
										<div className='col-auto'>
											<div className='form-check form-switch'>
												<input className='form-check-input' type='checkbox' checked={customer?.isGreenPhox} name='isGreenPhox' onChange={handleCheckChange} />
											</div>
										</div>
										<div className='col ms-n2'>
											{customer?.isGreenPhox ?
												<small className='text-success h5'> Enrolled</small> :
												<small className='text-danger h5'>Not Enrolled</small>
											}
										</div>
									</div>
								</Form.Group>
							</Col>
						</Row>
						<hr />
						<Button type='submit' className='btn btn-dark ms-2'>Continue</Button>
						<Link to='/patient' className='btn btn-link text-muted ms-2'>Cancel</Link>
					</Form>
				</Container>
			</Spinner>
		</>
	)
}

export default PatientEditor

import { QrScanner } from '@yudiel/react-qr-scanner';
import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import BarcodeReader from 'react-barcode-reader';
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import uniqid from 'uniqid';
import { AddressView, GoogleAddressField, SearchAddressFromHere } from '../../components/Address';
import { ButtonList } from '../../components/ButtonList';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { FormError, getDistance, getDistanceFromHere, isValidEmail, isValidPhone, toLocalDateTime } from '../../helpers';
import { storeShipper } from '../../stores/slice';
import SignatureType from './../../data/signature-type.json';
import StorageType from './../../data/storage-type.json';
import Shipmentpackages from './Shipmentpackages';
import { processBilling } from '../../helpers/processBilling';

//#region GraphQL

const updateShipment = /* GraphQL */ `
  mutation UpdateShipment(
    $input: UpdateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    updateShipment(input: $input, condition: $condition) {
      id
      number
      batch
      authCode
      shipFrom
      shipTo
      weight
      distance
      status
      services
      deliveryInstructions
      items
      billing
      attachment
      createdTime
      readyForPickupTime
      expectedPickupTime
      actualPickupTime
      pickupLocation {
        latitude
        longitude
        __typename
      }
      expectedDeliveryTime
      actualDeliveryTime
      deliveryLocation {
        latitude
        longitude
        __typename
      }
      outForReturnTime
      returnedTime
      returnLocation {
        latitude
        longitude
        __typename
      }
      extId
      extTrackingNumber
      extLabelId
      extLabelUrl
      route {
        id
        routeSequence
        routeDistance
        routeDuration
        createdAt
        updatedAt
        __typename
      }
      packageCount
      isGreenPhox
      customerId
      customer {
        id
        shipperGroupId
        shipperId
        name
        firstName
        lastName
        alias
        company
        email
        phone
        isGreenPhox
        active
        deliveryInstructions
        extId
        createdAt
        updatedAt
        __typename
      }
      shipperGroupId
      shipperGroup {
        id
        name
        alias
        image
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      shipperId
      shipper {
        id
        name
        alias
        image
        phone
        email
        shipperGroupId
        createdAt
        updatedAt
        __typename
      }
      carrierId
      carrier {
        id
        name
        alias
        image
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        name
        title
        company
        phone
        email
        image
        identityProof
        rating
        role
        active
        online
        device
        createdAt
        updatedAt
        userLocationId
        __typename
      }
      driverId
      driver {
        id
        name
        title
        company
        phone
        email
        image
        identityProof
        rating
        role
        active
        online
        device
        createdAt
        updatedAt
        userLocationId
        __typename
      }
      deliveryServiceId
      deliveryService {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        default
        sort
        active
        shipperId
        carrierId
        createdAt
        updatedAt
        __typename
      }
      boxId
      box {
        id
        shipperGroupId
        shipperId
        carrierId
        userId
        number
        name
        status
        createdAt
        updatedAt
        __typename
      }
      ratings {
        nextToken
        __typename
      }
      history {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      boxStatusHistory {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      shipmentRouteId
      __typename
    }
  }
`;

const getShipperQuery = /* GraphQL */ `
  query MyQuery($id: ID!) {
    getShipper(id: $id) {
		id
		name
		alias
		phone
		shipperGroup {
			id
			name
			alias
		}
		timezone {
			id
			name
			alias
		}
     	address
		{
			name
			address1
			address2
			address3
			city
			state
			country
			postalCode
			location {
				latitude
				longitude
			}
		}
		settings {
			items {
				key
				value
			}
		}
    }
  }
`;

const getDeliveryServicesQuery = /* GraphQL */ `
  query MyQuery ($shipperId: ID! $filter: ModelDeliveryServiceFilterInput ) {
    deliveryServicesByShipperId (
		shipperId: $shipperId
		filter: $filter
		) {
      items {
        id
        type
        name
		value
        pickupBy
        tatMin
        tatMax
        maxDistance
		sort
		active
		coverage
        carrierId
		carrier {
			id
			name
			alias
			active
			users(filter: {role: {eq: OWNER}}) {
				items {
					user {
						id
						name
					}
					role
				}
			}
		}
      }
      nextToken
    }
  }
`;

const createShipmentNumberMutation = /* GraphQL */ `
  mutation CreateShipmentNumber {
    createShipmentNumber
  }
`;

const createShipmentMutation = /* GraphQL */ `
  mutation CreateShipment($input: CreateShipmentInput!) {
    createShipment(input: $input) {
	  id
	  attachment
	  authCode
	  batch
	  billing
	  carrierId
	   carrier{
		id
		name
		alias
	  }
	  customerId
	  deliveryInstructions
	  deliveryServiceId
	  distance
	  driverId
	  shipTo
	  shipFrom
	  shipperId
	  shipperGroupId
	  status
	  deliveryInstructions
	  userId
	  weight
	  id
	  items
	  number
	  extId
	  deliveryService{
	  	name
	  	}
	  services
	  	}
	}
`;

const createShipmentStatusHistoryMutation = /* GraphQL */ `
  mutation CreateShipmentStatusHistory($input: CreateShipmentStatusHistoryInput!) {
    createShipmentStatusHistory(input: $input) {
		id
    }
  }
`;

const createShipmentRouteMutation = /* GraphQL */ `
  mutation CreateShipmentRoute($input: CreateShipmentRouteInput!) {
    createShipmentRoute(input: $input) {
		id
    }
  }
`;

const createEndiciaLabelMutation = /* GraphQL */ `
  mutation CreateEndiciaLabel($id: ID!) {
    createEndiciaLabel(id: $id)
  }
`;

const createFedexLabelMutation = /* GraphQL */ `
  mutation createFedexLabel ($id: ID!) {
    createFedexLabel (id: $id)
  }
`;

const createCxtLabelMutation = /* GraphQL */ `
  mutation createCxtLabel ($id: ID!) {
    createCxtLabel (id: $id)
  }
`;

const createCustomerMutation = /* GraphQL */ `
  mutation createCustomer ($input: CreateCustomerInput!) {
    createCustomer (input: $input) {
		id
	}
  }
`;

const updateCustomerMutation = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!  ) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

const sendCarrierNotificationMutation = /* GraphQL */ `
  query sendCarrierNotification ($shipmentId: ID!) {
    sendShipmentCarrierNotification (shipmentId: $shipmentId)
	sendShipmentCarrierUserNotification (shipmentId: $shipmentId)
  }
`;

const sendShipmentPatientNotification = /* GraphQL */ `
  query SendShipmentPatientNotification($shipmentId: ID!) {
    sendShipmentPatientNotification(shipmentId: $shipmentId)
  }
`;

const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;

const createUpsLabelMutation = /* GraphQL */ `
mutation CreateUpsLabel($id: ID!) {
  createUpsLabel(id: $id)
}
`;

const updateShipmentBox = /* GraphQL */ `
  mutation UpdateShipmentBox(
    $input: UpdateShipmentBoxInput!
  ) {
    updateShipmentBox(input: $input) {
      id    
    }
  }
`;

const createBoxStatusHistory = /* GraphQL */ `
mutation CreateBoxStatusHistory(
  $input: CreateBoxStatusHistoryInput!
  $condition: ModelBoxStatusHistoryConditionInput
) {
  createBoxStatusHistory(input: $input, condition: $condition) {
	id
	boxId
	userId
	shipmentId
  }
}
`;

const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      shipperGroupId
      shipperGroup {
        id
        name
        alias
      }
      shipperId
      shipper {
        id
        name
        alias
      }
      name
      firstName
      lastName
      alias
      company
      address {
        address1
        address2
        city
        state
        country
        postalCode
		location{
			latitude
			longitude
		}
      }
      email
      phone
      isGreenPhox
      active
      deliveryInstructions
      extId
      createdAt
      updatedAt
    }
  }
`;


//#endregion

const ShipmentCreate = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const myUser = useSelector((state) => state.slice.USER);
	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);

	const [spinner, showSpinner] = useState(false);
	const [shipment, setShipment] = useState({});
	const [shipmentBox, setShipmentBox] = useState([]);
	const [greenPhoxDelivery, setGreenPhoxDelivery] = useState(false)

	const [carriers, setCarriers] = useState();
	const [carriersByDistance, setCarriersByDistance] = useState();
	const [deliveryServices, setDeliveryServices] = useState([])
	const [deliveryServicesByDistance, setDeliveryServicesByDistance] = useState([])

	const [errors, setErrors] = useState({});
	const [packageErrors, setPackageErrors] = useState([]);

	const [customers, setCustomers] = useState([])
	const [currCustomerGreenPhox, setCurrCustomerGreenPhox] = useState(false)

	const [selectedCustomerId, setSelectedCustomerId] = useState({ id: "NEW" });
	const [updateCustomerAddress, setUpdateCustomerAddress] = useState(false)

	const [qrCodeData, setQRCodeData] = useState('');
	const [qrTemplate, setQrTemplate] = useState('Default');
	const [qrModal, showQRModal] = useState(false);
	const [facingMode, setFacingMode] = useState('environment');

	const [searchParams, setSearchParams] = useSearchParams()
	const [storageTypesSettings, setStorageTypesSettings] = useState([]);

	const scannerRef = useRef(null);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/shipment/create",
		})
	}, [])

	useEffect(() => {
		if (myShipper?.shipper?.id) {
			showSpinner(true)

			Promise.all([
				API.graphql({ query: getShipperQuery, variables: { id: myShipper.shipper.id } }),
				API.graphql({ query: getDeliveryServicesQuery, variables: { shipperId: myShipper.shipper.id, filter: { active: { eq: true } } } }),
			]).then(async (response) => {

				let shipFrom = {}
				const shipper = response[0].data.getShipper;
				if (!shipper?.address?.location?.latitude && !shipper?.address?.location?.longitude) toast.error('please contact support@phoxhealth.com to update you Profile')

				if (shipper) {
					shipFrom = {
						name: shipper.name,
						alias: shipper.alias,
						phone: shipper.phone,
						address: shipper.address,
						timezone: shipper.timezone
					}
				}
				let carriers = [];
				const deliveryServices = response[1].data.deliveryServicesByShipperId?.items.sort((a, b) => a.sort - b.sort);
				deliveryServices?.length > 0 && deliveryServices.forEach((item) => {
					if (item?.type === "TODAY" && item?.pickupBy < moment().format("HH:mm")) {
						item.disabled = true;
					}
					if (carriers.findIndex(x => x.id === item?.carrier?.id) < 0 && item?.carrier?.active !== false)
						carriers.push(item.carrier)
				});

				const carrier = carriers[0]
				let deliveryService = deliveryServices.filter(x => x.carrierId === carrier.id && !x.disabled)[0]
				if (deliveryService?.disabled) {
					deliveryService = null;
					toast.error("Delivery services are not available right now.")
				}
				const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);

				let services = [];

				// Adult Signature Required
				const signatureIndex = shipper?.settings?.items.findIndex(x => x.key === 'shipment_adult_signature_required');
				if (signatureIndex >= 0 && shipper?.settings?.items[signatureIndex].value === 'Yes') services.push('ADULT_SIGNATURE')

				//  photo ID Required
				const photoIdIndex = shipper?.settings?.items.findIndex(x => x.key === 'shipment_photo_id_required');
				if ((photoIdIndex >= 0 && shipper?.settings?.items[photoIdIndex].value === 'Yes')) {
					SignatureType["PHOTO_ID"] = "Photo ID Required"
				} else {
					SignatureType?.PHOTO_ID && delete SignatureType?.PHOTO_ID
				}

				// storage Type
				const coldChain = shipper?.settings?.items.findIndex(x => x.key === 'shipment_cold_chain_required')
				if (coldChain >= 0 && shipper?.settings?.items[coldChain].value === 'Yes') services.push('COLD_CHAIN')

				const dryIce = shipper?.settings?.items.findIndex(x => x.key === 'shipment_dry_ice_required')
				if (dryIce >= 0 && shipper?.settings?.items[dryIce].value === 'Yes') services.push('DRY_ICE')

				const refrigerated = shipper?.settings?.items.findIndex(x => x.key === 'shipment_refrigerated_required')
				if (refrigerated >= 0 && shipper?.settings?.items[refrigerated].value === 'Yes') services.push('REFRIGERATED')

				// Storage Type
				let storageTypes = [];
				const storage_refrigerated = shipper?.settings?.items.find(x => x.key === 'storage_refrigerated')
				if (storage_refrigerated?.value === 'Yes') storageTypes.push('REFRIGERATED')

				const storage_dry_ice = shipper?.settings?.items.find(x => x.key === 'storage_dry_ice')
				if (storage_dry_ice?.value === 'Yes') storageTypes.push('DRY_ICE')

				const storage_cold_chain = shipper?.settings?.items.find(x => x.key === 'storage_cold_chain')
				if (storage_cold_chain?.value === 'Yes') storageTypes.push('COLD_CHAIN')

				const storage_room_temperature = shipper?.settings?.items.find(x => x.key === 'storage_ambient')
				if (storage_room_temperature?.value === 'Yes') storageTypes.push('ROOM_TEMPERATURE')

				//qr template settings
				const qrTemplate = shipper?.settings?.items.findIndex(x => x.key === 'qr_code_template')
				if (qrTemplate >= 0 && shipper?.settings?.items[qrTemplate]?.value) setQrTemplate(shipper?.settings?.items[qrTemplate].value)

				let shipTo = {}
				let customer = {}
				if (searchParams.get("patient_id")) {
					let init = {
						body: { query: { bool: { must: [{ match: { id: searchParams.get("patient_id") } }] } } }
					}
					const path = `/search/customer?size=${100}`;
					await API.post('api', path, init).then(res => {
						if (res.hits.hits.length > 0) {
							customer = res.hits.hits[0]._source
							shipTo = {
								id: customer?.id,
								name: customer?.name,
								firstName: customer.firstName || customer?.name,
								lastName: customer.lastName,
								company: customer?.company,
								email: customer?.email,
								phone: customer?.phone,
								address: customer?.address
							}
						}
					})
				}
				setStorageTypesSettings(storageTypes)
				setDeliveryServices(deliveryServices);
				setDeliveryServicesByDistance(deliveryServices);
				setCarriers(carriers);
				setCarriersByDistance(carriers);
				setShipment({
					...shipment,
					shipFrom: shipFrom,
					shipTo: shipTo,
					shipperGroupId: shipper.shipperGroup.id,
					shipperGroup: shipper.shipperGroup,

					shipperId: shipper.id,
					shipper: shipper,

					carrierId: carrier?.id,
					carrier: carrier || null,

					deliveryServiceId: deliveryService?.id,
					deliveryService: deliveryService || null,
					deliveryInstructions: customer?.deliveryInstructions,

					expectedPickupTime: expectedPickupTime,
					expectedDeliveryTime: expectedDeliveryTime,

					services: services,
					packageCount: 1,
					isGreenPhox: false,

					items: [[{
						id: 'rx',
						name: 'Product'
					}]]
				});


				showSpinner(false);
			}).catch((error) => console.error(error))
		}
	}, [myShipper]);



	useEffect(() => {
		if (qrCodeData.length > 0) {
			const scanData = qrCodeData.split('^');
			parseQrData(scanData)
		}
	}, [qrCodeData]);

	useEffect(() => {
		if (qrTemplate === 'AAH_USPS' && !qrCodeData) {
			deliveryServices && serviceBydistance()
		} else if (!qrTemplate === 'AAH_USPS') {
			deliveryServices && serviceBydistance()
		}
	}, [shipment?.distance])

	//get distance from chicago IL for AAH main room
	const getDistanceFromChicagoIl = async (shiToLocation) => {
		const chicagoLocation = { latitude: 42.047586035877956, longitude: -87.98072053317183 }
		return await getDistanceFromHere(chicagoLocation, shiToLocation);
	}

	// select carrier & service type by distance
	const serviceBydistance = () => {
		if (getSetting('enroll_green_phox') !== 'Yes') {
			if (shipment?.distance > shipment?.deliveryService?.maxDistance) {

				let newServicesByDistance = deliveryServices?.filter((item) => !item?.maxDistance || shipment?.distance < item?.maxDistance);

				const uniqueCarrierIds = new Set();
				const carriersByDistance = [];

				newServicesByDistance.forEach((item) => {
					const carrierId = item?.carrier?.id;

					if (carrierId && !uniqueCarrierIds.has(carrierId)) {
						uniqueCarrierIds.add(carrierId);
						carriersByDistance.push(item.carrier);
					}
				});

				const carriersArray = Array.from(carriersByDistance);

				setCarriersByDistance(carriersArray);
				setDeliveryServicesByDistance(newServicesByDistance);

				const carrier = carriersArray[0]
				const deliveryService = newServicesByDistance.filter(x => x.carrierId === carrier.id)[0]
				const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);

				setShipment({
					...shipment,

					carrierId: carrier?.id,
					carrier: carrier,

					deliveryServiceId: deliveryService?.id,
					deliveryService: deliveryService,

					expectedPickupTime: expectedPickupTime,
					expectedDeliveryTime: expectedDeliveryTime,

				});

			} else if (shipment?.deliveryService && shipment?.carrier) {

				setCarriersByDistance(carriers);
				setDeliveryServicesByDistance(deliveryServices);

				const carrier = carriers[0]
				const deliveryService = deliveryServices.filter(x => x.carrierId === carrier.id)[0]
				const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);

				setShipment({
					...shipment,

					carrierId: carrier?.id,
					carrier: carrier,

					deliveryServiceId: deliveryService?.id,
					deliveryService: deliveryService,

					expectedPickupTime: expectedPickupTime,
					expectedDeliveryTime: expectedDeliveryTime,

				});
			}
		}
	}


	const getAuthCode = () => {
		const min = 100000
		const max = 999999
		return Math.floor(Math.random() * (max - min + 1) + min)
	}

	const getSetting = (key) => {
		const index = shipment?.shipper?.settings?.items.findIndex(x => x.key === key);
		if (index >= 0) return shipment?.shipper?.settings?.items[index].value
		else return false
	}

	//#region Search
	const searchCustomer = (() => {
		let timeout;

		return (keyword, callback) => {
			clearTimeout(timeout);

			timeout = setTimeout(async () => {
				let items = [{ label: ' -- Add New Patient -- ', value: 'NEW' }];
				try {
					const apiName = 'api';
					const path = `/search/customer?size=100&from=0`;
					let init = {
						body: {
							sort: [
								{ "_score": { "order": "desc" } },
								{
									"updatedAt": {
										"order": "desc",
										"unmapped_type": "date"
									}
								}
							],
							query: {
								bool: {
									must: [
										{ match: { shipperGroupId: myShipper?.shipper?.shipperGroupId } },
										{ match: { active: true } }
									]
								}
							}
						}
					};
					if (keyword) {
						delete init.body.sort;
						let fields = [
							"extId",
							"name"
						];
						init.body.query.bool.must.push(
							{
								"multi_match": {
									"query": keyword,
									"fields": fields
								}
							}
						);
					}

					let data = myShipper?.shipper?.shipperGroupId && await API.post(apiName, path, init);
					setCustomers(data?.hits?.hits);
					data?.hits?.hits.forEach(item => items.push({
						label: item?._source?.name,
						value: item?._source?.id
					}));
				} catch (error) {
					showSpinner(false);
					console.error('error from elastic search customer', error);
				}
				callback(items);
			}, 500);
		};
	})();

	//#endregion

	//#region Ship To
	const handleShipToSelect = async (e) => {
		showSpinner(true)
		let shipTo = { id: 'NEW' }
		setSelectedCustomerId({ id: 'NEW', isGreenPhox: false });
		setGreenPhoxDelivery(false)
		let distance = 0
		let deliveryInstructions = '';
		let newServices = shipment?.services
		let newCarriers = []
		let customer = null;
		if (e.value !== 'NEW') {
			let { data } = await API.graphql({ query: getCustomer, variables: { id: e.value } })
			customer = data?.getCustomer
			if (customer) {
				if (!customer?.isGreenPhox) {
					customer.isGreenPhox = false
				}
				setSelectedCustomerId(customer)
				deliveryInstructions = customer?.deliveryInstructions
				shipTo = {
					id: customer?.id,
					name: customer?.name,
					firstName: customer.firstName || customer?.name,
					lastName: customer.lastName,
					extId: customer?.extId,
					company: customer?.company,
					email: customer?.email,
					phone: customer?.phone,
					address: customer?.address
				}
				if (!customer?.address?.location?.latitude && !customer?.address?.location?.longitude) {
					toast.error('Please enter patient address again')
					shipTo.id = 'NEW'
					setUpdateCustomerAddress(true)
				} else {
					if (shipTo?.address?.state === 'IL' && shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && shipment?.carrier?.name?.includes("IL")) {
						distance = await getDistanceFromChicagoIl(shipTo?.address?.location);
					} else {
						distance = await getDistanceFromHere(customer.address.location, shipment.shipFrom.address.location);
					}
				}

				if (!customer?.phone?.trim() || !isValidPhone(customer?.phone?.trim())) {
					shipTo.id = 'NEW'
					toast.error('Please enter valid phone number')
					setUpdateCustomerAddress(true)
				}

				if (getSetting('enroll_green_phox') === 'Yes' && customer?.isGreenPhox === true) {
					setGreenPhoxDelivery(true)
					newServices.push('ADULT_SIGNATURE')
				} else {
					setGreenPhoxDelivery(false)
				}
			} else {
				toast.error("Something went wrong while selecting the patient. Please try again.")
			}

		}

		shipTo.isGreenPhox = (getSetting('enroll_green_phox') === 'Yes' && customer?.isGreenPhox === true) ? true : false

		if (customer?.isGreenPhox === true && getSetting('enroll_green_phox') === 'Yes') {
			newCarriers = carriers.filter(x => x.name !== 'FedEx' && x.name !== 'USPS' && x.name !== 'UPS');
			setCarriersByDistance(newCarriers);
		} else {
			setCarriersByDistance(carriers);
		}

		setQRCodeData('')
		setShipment({
			...shipment,
			shipTo: shipTo,
			customerId: shipTo.id,
			distance: distance,
			deliveryInstructions: deliveryInstructions,
			isGreenPhox: shipTo.isGreenPhox,
			services: newServices
		})

		showSpinner(false)

	}

	const handleAddressSelect = async (values, placeId) => {
		const places = await geocodeByPlaceId(placeId);
		const latLong = await getLatLng(places[0]);

		let street_number = '';
		let sub_locality1 = '';
		let sub_locality2 = '';
		let sub_locality3 = '';
		let route = '';
		let city = '';
		let state = '';
		let postalCode = '';
		let address2 = ''

		let location = {};
		if (latLong) {
			location.latitude = latLong.lat;
			location.longitude = latLong.lng;
		}

		places && places.length > 0 && places[0].address_components.forEach((address) => {
			if (address.types.includes('street_number')) street_number = address.short_name;
			if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
			if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
			if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
			if (address.types.includes('route')) route = address.short_name;
			if (address.types.includes('locality')) city = address.short_name;
			if (address.types.includes('administrative_area_level_1')) state = address.short_name;
			if (address.types.includes('postal_code')) postalCode = address.long_name;
			address2 = ''
		});

		const address = {
			address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route}`,
			address2,
			postalCode,
			city,
			state,
			location
		}

		const distance = await getDistance(address.location, shipment.shipFrom.address.location);
		setShipment({
			...shipment,
			shipTo: { ...shipment.shipTo, address: address },
			distance: distance
		})
	};


	const handleShipToChange = (e) => {
		setShipment({ ...shipment, shipTo: { ...shipment.shipTo, [e.target.name]: e.target.value } })
		setUpdateCustomerAddress(true);
	}
	const handleAddressChange = (e) => {
		setShipment({ ...shipment, shipTo: { ...shipment.shipTo, address: { ...shipment.shipTo.address, [e.target.name]: e.target.value } } })
		setUpdateCustomerAddress(true);
	}
	const handleAddress1Change = (address1) => {
		setShipment({ ...shipment, shipTo: { ...shipment.shipTo, address: { ...shipment.shipTo.address, address1: address1 } } })
		setUpdateCustomerAddress(true);
	}
	const handleShipToPhoneChange = (phone) => {
		setShipment({ ...shipment, shipTo: { ...shipment.shipTo, phone: `+${phone}` } })
		setUpdateCustomerAddress(true);
	}

	//#endregion

	//#region QR Scan
	const handleCameraScan = (data) => {
		setQRCodeData(data.replace(/[\\/]/g, ''));
		showQRModal(false)
	};

	const handleCameraError = (error) => {
		console.error(error.message);
	};

	const handlePermission = (status) => {
		if (status === 'denied') {
			toast.error('Camera permission is required to scan the Shipment.');
		}
	};

	const handleScanError = (error) => {
		console.error(error);
		// window.location.reload(); // package issue
	};

	const handleScan = (data) => {
		data = data.replace(/[\\/]/g, '');

		try {
			if (getSetting('phox_tail_required') === 'Yes' && data.split('^')[0] === 'RXN') {
				scanPackagedata(data)
			} else {
				setQRCodeData(data);
			}
		} catch (error) {
			toast.error('Opps,something went wrong, please try again')
		}
	};


	const scanPackagedata = (data) => {
		setPackageErrors([])
		if (data.startsWith('\\')) {
			data = data.slice(1);
		}
		if (data.endsWith('\\')) {
			data = data.slice(0, -1);
		}
		let dataArray = data.split('^');

		const resultArray = [];
		for (let i = 0; i < dataArray.length; i += 2) {
			const key = dataArray[i];
			const values = dataArray[i + 1]?.split(',');

			const obj = {};
			obj[key] = values;
			resultArray.push(obj);
		}

		let updatedParcelCount = [[]];
		for (let i = 0; i < resultArray[0].RXN.length; i++) {
			updatedParcelCount[0].push({
				id: 'NEW',
				name: 'Product',
				number: resultArray[0].RXN[i]?.trim(),
				ndc: resultArray[1].NDC[i]?.trim(),
				qty: resultArray[2].QTY[i]?.trim(),
				tier: 0
			});
		}

		if (updatedParcelCount.length > 0) {
			setShipment({
				...shipment,
				items: updatedParcelCount.length > 0 ? updatedParcelCount : [[{ id: 'NEW', name: 'Product', number: '', ndc: '', qty: '' }]],
			});
		} else {
			toast.error('No data');
		}
	};


	const getPatientData = async (mrn = "", name = "", phone = "") => {
		showSpinner(true);
		let patientSpinner = toast.loading("Searching for patinet");
		try {
			const apiName = 'api';
			const path = `/search/customer?size=100&from=0`;
			let init = {
				body: {
					sort: [
						{ "_score": { "order": "desc" } },
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: [
								{ match: { shipperGroupId: myShipper?.shipper?.shipperGroupId } }
							]
						}
					}
				}
			};
			if (name && phone) {
				init.body.query.bool.must.push(
					{
						"match": {
							"name": name
						}
					},
					{
						"match": {
							"phone": phone
						}
					}
				)
			}
			if (mrn) {
				init.body.query.bool.filter = {
					"terms": {
						"extId": [mrn]
					}
				}

			}

			if (myShipper?.shipper?.shipperGroupId) {
				let data = await API.post(apiName, path, init);
				if (data.hits.hits.length > 0) {
					toast.remove(patientSpinner)
					toast.success("patient found")
					showSpinner(false);
					return data.hits.hits[0]._source
				} else {
					showSpinner(false);
					toast.remove(patientSpinner)
					toast.error("Patient not found. Please add new patient.")
					return false
				}
			} else {
				showSpinner(false);
				toast.error("Something went wrong")
				console.error("search Patiner error");
				return false
			}
		} catch (error) {
			showSpinner(false);
			toast.error("Something went wrong")
			console.error('error from elastic search customer', error);
		}
	}

	const parseQrData = async (data) => {
		try {

			if (qrTemplate === 'AAH_USPS') {
				const address = `${data[2]}, ${data[3]}, ${data[4]}, ${data[5]}`;
				const selectedAddress = await geocodeByAddress(address);
				const LatLong = await getLatLng(selectedAddress[0]);

				let distance = '';
				let location = {};
				if (LatLong) {
					location.latitude = LatLong.lat;
					location.longitude = LatLong.lng;
					if (shipment?.shipTo?.address?.state === 'IL' && shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && shipment?.carrier?.name?.includes("IL")) {
						distance = await getDistanceFromChicagoIl(location);
					} else {
						distance = await getDistanceFromHere(shipment.shipFrom.address.location, location);
					}
				}
				let APIMailRoomCarrier
				let deliveryService
				carriers.map((item) => {
					if (item?.name.toLowerCase() === 'usps') APIMailRoomCarrier = item
				})
				if (APIMailRoomCarrier) {
					deliveryService = deliveryServices.filter(x => x.carrierId === APIMailRoomCarrier.id).find((item) => item?.name?.toLowerCase() === "first-class mail")
				}
				if (deliveryService) {
					setShipment({
						...shipment,
						shipTo: {
							...shipment.shipTo,
							id: 'NEW',
							name: data[0],
							firstName: data[0],
							lastName: '',
							email: '',
							phone: data[1]?.substring(0, 2) === '+1' ? data[1] : `+1${data[1]}`,
							address: {
								address1: data[2],
								address2: '',
								city: data[3],
								state: data[4],
								postalCode: data[5],
								location: location,
							},
						},
						distance: distance,
						carrier: APIMailRoomCarrier,
						carrierId: APIMailRoomCarrier?.id,
						deliveryService: deliveryService,
						deliveryServiceId: deliveryService?.id,
						customerId: "new"

					});
				}

			} else if (qrTemplate === 'MetroHealth') {
				const parseData = (index) => (data?.length > 11 ? data[index]?.trim() : data[index - 1]?.trim())

				let shipTo = { id: 'NEW' }
				let deliveryInstructions = '';
				let newCarriers = []
				let newServices = shipment?.services
				// searching patinet with MRN number
				let patientData = parseData(2)?.length > 0 && await getPatientData(parseData(2))

				if (patientData) {
					shipTo.id = patientData?.id;
					deliveryInstructions = patientData?.deliveryInstructions;
					setUpdateCustomerAddress(true)
					setSelectedCustomerId(patientData)

					if (!patientData?.isGreenPhox) {
						patientData.isGreenPhox = false
					}

					if (getSetting('enroll_green_phox') === 'Yes' && patientData?.isGreenPhox === true) {
						setGreenPhoxDelivery(true)
						newServices.push('ADULT_SIGNATURE')
						shipTo.isGreenPhox = true
						newCarriers = carriers.filter(x => x.name !== 'FedEx' && x.name !== 'USPS' && x.name !== 'UPS');
						setCarriersByDistance(newCarriers);
					} else {
						setGreenPhoxDelivery(false)
						setCarriersByDistance(carriers);
					}
				}

				const address = `${parseData(6)}, ${parseData(7)}, ${parseData(8)}, ${parseData(9)}`;
				const selectedAddress = await geocodeByAddress(address);
				const LatLong = await getLatLng(selectedAddress[0]);

				let distance = '';
				let location = {};
				if (LatLong) {
					location.latitude = LatLong.lat;
					location.longitude = LatLong.lng;
					const shipToLocation = {
						latitude: LatLong.lat,
						longitude: LatLong.lng,
					};
					distance = await getDistanceFromHere(shipToLocation, shipment.shipFrom.address.location);
				}

				let updatedParcelCount = [[]];
				if (data.length > 11 && data[11]?.length > 0) {
					let scanParcelData = data[11]
					let splited_orderNumber = scanParcelData.split(',')
					splited_orderNumber.map((item) => {
						return updatedParcelCount[0].push({ id: 'NEW', name: 'Product', number: item })
					})
				} else if (data.length === 11 && data[10]?.length > 0) {
					let scanParcelData = data[10]
					let splited_orderNumber = scanParcelData.split(',')
					splited_orderNumber.map((item) => {
						return updatedParcelCount[0].push({ id: 'NEW', name: 'Product', number: item })
					})
				}

				setShipment({
					...shipment,
					extId: parseData(1),
					shipTo: {
						...shipment.shipTo,
						id: shipTo?.id,
						extId: parseData(2),
						name: parseData(3),
						firstName: parseData(3),
						lastName: parseData(4),
						email: parseData(6),
						phone: parseData(5).substring(0, 2) === '+1' ? parseData(5) : `+1${parseData(5)}`,
						address: {
							address1: parseData(7),
							address2: '',
							city: parseData(8),
							state: parseData(9),
							postalCode: parseData(10),
							location: location,
						},
					},
					customerId: shipTo?.id,
					distance: distance,
					deliveryInstructions,
					items: updatedParcelCount[0].length ? updatedParcelCount : [[{ id: 'NEW', name: 'Product', number: '' }]],
					isGreenPhox: shipTo.isGreenPhox,
					services: newServices
				})

			} else {
				const parseData = (index) => (data?.length > 9 ? data[index].trim() : data[index - 1].trim());
				const address = `${parseData(5)}, ${parseData(6)}, ${parseData(7)}, ${parseData(8)}`;
				const selectedAddress = await geocodeByAddress(address);
				const LatLong = await getLatLng(selectedAddress[0]);

				let distance = '';
				let location = {};
				if (LatLong) {
					location.latitude = LatLong.lat;
					location.longitude = LatLong.lng;
					const shipToLocation = {
						latitude: LatLong.lat,
						longitude: LatLong.lng,
					};
					distance = await getDistanceFromHere(shipToLocation, shipment.shipFrom.address.location);
				}

				let updatedParcelCount = [[]];
				if (data.length > 9 && data[9]?.length > 0) {
					let scanParcelData = data[9]
					let splited_orderNumber = scanParcelData.split(',')
					splited_orderNumber.map((item) => {
						return updatedParcelCount[0].push({ id: 'NEW', name: 'Product', number: item })
					})
				} else if (data.length === 9 && data[8]?.length > 0) {
					let scanParcelData = data[8]
					let splited_orderNumber = scanParcelData.split(',')
					splited_orderNumber.map((item) => {
						return updatedParcelCount[0].push({ id: 'NEW', name: 'Product', number: item })
					})

				}

				setShipment({
					...shipment,
					extId: parseData(1),
					shipTo: {
						...shipment.shipTo,
						id: 'NEW',
						name: parseData(2),
						firstName: parseData(2),
						lastName: '',
						email: parseData(4),
						phone: parseData(3).substring(0, 2) === '+1' ? parseData(3) : `+1${parseData(3)}`,
						address: {
							address1: parseData(5),
							address2: '',
							city: parseData(6),
							state: parseData(7),
							postalCode: parseData(8),
							location: location,
						},
					},
					customerId: "new",
					distance: distance,
					items: updatedParcelCount.length ? updatedParcelCount : [[{ id: 'NEW', name: 'Product', number: '' }]],
				});
			}
		} catch (error) {
			console.error('scan error', error)
			toast.error('Oops! Looks like there was an error trying to process your request');
		}
	};

	//#endregion

	//#region Items
	const handleItems = (items,) => {
		setShipment({ ...shipment, items })
	}


	const handlePackageCount = (items, packageCount) => {
		setShipment({ ...shipment, packageCount: packageCount, items })
	}
	//#endregion


	const getPickupDeliveryTime = (deliveryService) => {
		let expectedPickupTime = null
		let expectedDeliveryTime = null

		if (deliveryService?.type === 'TODAY') {
			if (deliveryService.tatMax <= 4) {
				expectedPickupTime = moment()
				expectedDeliveryTime = moment().add(deliveryService.tatMax, 'hours')
			} else {
				expectedPickupTime = moment().add(1, 'hours')
				expectedDeliveryTime = moment().add(deliveryService.tatMax + 1, 'hours')
				if (expectedDeliveryTime.isAfter(moment.tz(expectedPickupTime, myShipper?.shipper?.timezone?.id).endOf('day'))) {
					expectedDeliveryTime = moment.tz(expectedPickupTime, myShipper?.shipper?.timezone?.id).endOf('day')
				}
			}
		} else if (deliveryService?.type === 'TOMORROW') {
			const dateString = moment().add(1, 'days').format('YYYY-MM-DD')
			const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
			expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
			expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
		} else if (deliveryService?.type === 'MONDAY') {
			const dateString = moment().add(8 - moment().isoWeekday(), 'days').format('YYYY-MM-DD')
			const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
			expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
			expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
		}
		return {
			expectedPickupTime: expectedPickupTime?.unix(),
			expectedDeliveryTime: expectedDeliveryTime?.unix()
		}
	}

	const validateShipment = async () => {
		const error = {}
		showSpinner(true);

		if (!shipment?.deliveryServiceId) { error.deliveryService = 'Please select any (available) delivery service'; toast.error(error.deliveryService) }

		if (!shipment?.shipFrom?.address?.location.latitude && !shipment?.shipFrom?.address?.location.longitude) { error.shipFrpm = 'please contact support@phoxhealth.com to update you Profile'; toast.error(error.shipFrpm) }

		if (shipment?.extId) {
			let init = {
				body: {
					"query": {
						"bool": {
							"must": [
								{
									"term": {
										"extId": shipment?.extId
									}
								},
								{
									"term": {
										"shipperGroupId": myShipper?.shipper?.shipperGroupId
									}
								},
								{
									"terms": {
										"status.keyword": ["OPEN", "READY_FOR_PICKUP", "IN_TRANSIT", "OUT_FOR_DELIVERY"]
									}
								}
							]
						}
					}
				}
			}

			const { hits } = await API.post("api", "/search/shipment", init)
			if (hits?.hits?.length > 0) {
				error.extId = 'Order Number / Reference Number is already in use'
			}
		}

		if (shipment?.weight < 0) error.weight = 'Please enter valid weight'
		if (shipment?.weight?.toString()?.trim()?.startsWith('.')) error.weight = 'Please enter valid weight'

		if (shipment?.carrier?.alias.toLowerCase() === 'fedex' || shipment?.carrier?.alias.toLowerCase() === 'ups' || shipment?.carrier?.alias.toLowerCase() === 'usps') {
			if (!shipment?.weight) error.weight = 'Weight is required'
		}
		if (shipment?.weight == 0) error.weight = 'Please enter valid weight!'
		if (shipment?.carrier?.alias.toLowerCase() === 'usps' && shipment?.weight) {
			if (shipment?.shipperGroup?.alias.toLowerCase() === "metrohealth") {
				if (shipment?.weight >= 1.0 && shipment?.deliveryService?.value?.toLowerCase() !== 'priority') {
					toast.error('Service type not supported for the given weight, please select priority mail');
					error.weight = 'Service type not supported for the given weight, please select priority mail';
				}
			} else
				if (shipment?.weight >= 1.0 && shipment?.deliveryService?.value.toLowerCase() === 'first') {
					toast.error('Service type not supported for the given weight');
					error.weight = 'Service type not supported for the given weight';
				}
		}

		if (shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && ["WI", "IL"].includes(shipment?.shipTo?.address?.state)) {
			if (shipment?.carrier?.name?.includes("WI")) {
				if (shipment?.distance > 55) {
					error.distance = 'Distance is more then 55 mi, please select another Courier';
				}
			} else if (shipment?.carrier?.name?.includes("IL")) {
				const chicagoDistance = await getDistanceFromChicagoIl(shipment?.shipTo?.address?.location);
				if (chicagoDistance > 55) {
					error.distance = 'Distance is more then 55 mi, please select another Courier';
				}
			}
		}

		// Validate Ship To Address
		if (Object.keys(shipment?.shipTo)?.length > 0) {
			if (!shipment?.shipTo?.firstName) error.shipTo_firstName = 'First Name is required'
			if (!shipment?.shipTo?.address?.address1) error.shipTo_Address1 = 'Address 1 is required'
			if (!shipment?.shipTo?.address?.city) error.shipTo_City = 'City is required'
			if (!shipment?.shipTo?.address?.state) error.shipTo_State = 'State is required'
			if (!shipment?.shipTo?.address?.postalCode) error.shipTo_PostalCode = 'Postal Code is required'
			if (!shipment?.shipTo?.address?.location?.latitude && !shipment?.shipTo?.address?.location?.longitude) error.shipTo_Address1 = 'Please enter the address again'
			if (!isValidPhone(shipment?.shipTo?.phone?.trim()) || !shipment?.shipTo?.phone?.trim() || shipment?.shipTo?.phone?.trim().length < 10) error.phone = 'Please enter a valid phone number';
			if (!isValidEmail(shipment?.shipTo?.email?.trim())) error.email = 'Please enter a valid email'
		} else {
			error.shipTo = 'Delivery location is required'
		}

		// dubs MRN 
		if (shipment?.shipTo?.extId && (selectedCustomerId?.id === "NEW" || selectedCustomerId?.id === "new")) {
			let init = {
				body: {
					query: {
						bool: {
							must: [
								{ match: { shipperGroupId: myShipper?.shipper?.shipperGroupId } },
								{ match: { extId: shipment?.shipTo?.extId } }
							]
						}
					}
				}
			}
			const { hits } = await API.post("api", "/search/customer", init)
			if (hits?.hits.length > 0) { error['mrn'] = 'User already exsits with the same MRN number, Please enter another number' }
		}

		if (shipment?.deliveryService?.type === 'FUTURE') {
			if (!shipment?.expectedDeliveryTime) error.future = 'Future date is required'
		}

		if (shipment?.deliveryService?.maxDistance && shipment?.carrier?.alias.toLowerCase() !== 'usps' && shipment?.carrier?.alias.toLowerCase() !== 'fedex') {
			if (shipment?.distance > shipment?.deliveryService?.maxDistance) {
				error.distance = `Delivery location is ${shipment?.distance} mi away, it should be within ${shipment?.deliveryService?.maxDistance} mi`
			}
		}

		if (getSetting('shipment_order_number_required') === 'Yes' && !shipment.extId) {
			error.extId = 'Order Number / Reference Number is required'
		}

		if (shipment?.isGreenPhox && !shipment.boxId) error.box = 'Box is required, please select a box'

		if (shipment?.deliveryService?.coverage !== null) { // Final check for zipcode if radius is allowed
			if (shipment?.deliveryService?.coverage?.length !== 0 && !shipment?.deliveryService?.coverage?.includes(shipment?.shipTo?.address?.postalCode?.split('-')[0])) {
				if (shipment?.shipTo?.id === 'NEW') error.shipTo_Address1 = 'Service is not available at this address!'
				else error.shipTo = 'Service is not available at this address!'
			}
		}

		if (getSetting('phox_tail_required') === 'Yes') {
			let errorList = [];
			shipment?.items.forEach((pkg, pkgIndex) => {
				pkg.forEach((item, itemIndex) => {
					let errors = {};

					if (!item.number || item.number.trim() === '') {
						errors.number = 'Reference no. is required';
					}
					if ((!item.ndc || item.ndc.trim() === '')) {
						errors.ndc = 'NDC is required';
					}
					if ((!item.qty || item.qty.trim() === '')) {
						errors.qty = 'QTY is required';
					}

					if (Object.keys(errors).length > 0) {
						errorList.push({ pkgIndex, itemIndex, errors });
					}
				});
			});

			setPackageErrors(errorList);
			if (errorList.length > 0) error.items = "Reference Number, NDC, QTY is required";
		}

		console.log(error);
		setErrors(error)
		showSpinner(false);

		return error
	}


	const createShipment = async () => {
		let error = await validateShipment()
		if (Object.keys(error) <= 0) {
			showSpinner(true);
			const numberResponse = await API.graphql({ query: createShipmentNumberMutation })
			const number = parseInt(numberResponse.data.createShipmentNumber.replace(/['"]+/g, ''));
			const tasks = [];
			const shipmentId = uniqid();
			const customerId = (shipment.customerId === 'NEW' || shipment.customerId === 'new') ? uniqid() : shipment.customerId;
			const shipToName = (shipment.shipTo.firstName && shipment.shipTo.lastName) ? shipment.shipTo.firstName + " " + shipment.shipTo.lastName : shipment.shipTo.firstName ? shipment.shipTo.firstName : shipment.shipTo?.name
			const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(shipment?.deliveryService);
			const shipmentInput = {
				id: shipmentId,
				shipmentRouteId: shipmentId,
				status: 'OPEN',
				number: number,
				authCode: getAuthCode(),
				shipFrom: JSON.stringify(shipment.shipFrom),
				shipTo: JSON.stringify({
					name: shipToName,
					firstName: shipment.shipTo.firstName,
					lastName: shipment.shipTo.lastName,
					company: shipment?.shipTo?.company,
					phone: shipment?.shipTo?.phone?.trim(),
					email: shipment.shipTo.email,
					address: shipment.shipTo.address
				}),

				shipperGroupId: shipment.shipperGroupId,
				shipperId: shipment.shipperId,
				carrierId: shipment.carrierId,
				deliveryServiceId: shipment.deliveryServiceId,
				customerId: customerId,
				userId: myUser.id,

				services: shipment.services,

				weight: shipment?.weight,
				distance: shipment.distance,
				deliveryInstructions: shipment.deliveryInstructions,
				items: JSON.stringify(shipment.items),
				packageCount: shipment?.packageCount,

				createdTime: moment().unix(),
				expectedPickupTime: expectedPickupTime || shipment?.expectedPickupTime,
				expectedDeliveryTime: expectedDeliveryTime || shipment?.expectedDeliveryTime,

				extId: shipment.extId,
				extTrackingNumber: shipment.extTrackingNumber,
				extLabelUrl: shipment.extLabelUrl,

				isGreenPhox: shipment?.isGreenPhox,
				boxId: shipment?.boxId
			}

			if (getSetting('shipment_default_status_ready_for_pickup') === 'Yes') {
				shipmentInput.status = 'READY_FOR_PICKUP'
			}

			!shipment?.extId && delete shipmentInput?.extId
			!shipment?.boxId && delete shipmentInput?.boxId
			!shipment?.weight && delete shipmentInput?.weight
			!shipment?.shipTo?.phone && delete shipmentInput?.shipTo?.phone
			//#region Shipment 
			tasks.push(API.graphql({ query: createShipmentMutation, variables: { input: shipmentInput } }))
			//#endregion

			//#region Status History
			const shipmentHistoryInput = {
				id: uniqid(),
				shipmentId: shipmentId,
				userId: myUser.id,
				status: shipmentInput.status,
				createdTime: shipmentInput.createdTime
			}
			tasks.push(API.graphql({ query: createShipmentStatusHistoryMutation, variables: { input: shipmentHistoryInput } }))
			//#endregion

			//#region Shipment Route
			const shipmentRouteInput = {
				id: shipmentId,
				routeSequence: -1,
				routeDuration: -1,
				routeDistance: -1
			}
			tasks.push(API.graphql({ query: createShipmentRouteMutation, variables: { input: shipmentRouteInput } }))
			//#endregion

			//#region Customer
			if (updateCustomerAddress || (shipment.customerId === 'NEW' || shipment.customerId === 'new')) {
				const customerName = (shipment.shipTo.firstName && shipment.shipTo.lastName) ? shipment.shipTo.firstName + " " + shipment.shipTo.lastName : shipment.shipTo.firstName ? shipment.shipTo.firstName : shipment.shipTo?.name
				const customerInput = {
					id: customerId,
					active: true,
					shipperGroupId: shipment.shipperGroupId,
					shipperId: shipment.shipperId,
					firstName: shipment.shipTo.firstName,
					lastName: shipment?.shipTo?.lastName,
					name: customerName,
					company: shipment.shipTo.company,
					email: shipment.shipTo.email,
					phone: shipment.shipTo.phone,
					address: shipment.shipTo.address,
					deliveryInstructions: shipment?.deliveryInstructions?.trim(),
					extId: shipment?.shipTo?.extId
				}

				if (greenPhoxDelivery) {
					customerInput.isGreenPhox = greenPhoxDelivery;
				}
				// Create Customer
				if ((updateCustomerAddress || currCustomerGreenPhox) && selectedCustomerId?.id !== 'NEW') {
					customerInput.id = selectedCustomerId?.id
					tasks.push(API.graphql({ query: updateCustomerMutation, variables: { input: customerInput } }))
				} else {
					tasks.push(API.graphql({ query: createCustomerMutation, variables: { input: customerInput } }))
				}

			}
			//#endregion

			//#region Carrier Notification
			if (shipment?.deliveryService?.type === 'TODAY') {
				tasks.push(API.graphql({ query: sendCarrierNotificationMutation, variables: { shipmentId: shipmentId } }))
			}
			//#endregion

			if (shipment?.isGreenPhox && shipment?.boxId) tasks.push(API.graphql({ query: updateShipmentBox, variables: { input: { id: shipment?.boxId, status: 'IN_USE', carrierId: shipment?.carrierId, shipmentId: shipmentId } } }))

			//#endregion
			await Promise.all(tasks)

			if (getSetting('shipment_default_status_ready_for_pickup') === 'Yes') {
				try {
					await API.graphql({ query: updateShipment, variables: { input: { id: shipmentId, status: 'READY_FOR_PICKUP', readyForPickupTime: moment().unix() } } });
					await API.graphql({ query: sendShipmentPatientNotification, variables: { shipmentId: shipmentId } });
				} catch (error) {
					console.error(error)
				}
			}
			//#endregion

			if (shipment?.isGreenPhox && shipment?.boxId) {
				let statusInput = {
					boxId: shipment.boxId,
					shipmentId: shipmentId,
					userId: myUser?.id,
					status: 'IN_USE',
					createdTime: moment().unix()
				}
				try {
					await API.graphql({ query: createBoxStatusHistory, variables: { input: statusInput } })
				} catch (error) {
					toast.error('Opps,somthing went wrong')
					console.error(error);
				}
			}

			try {
				//#region USPS
				if (shipment.carrier.alias.toLowerCase() === 'usps') {
					let response = await API.graphql({ query: createEndiciaLabelMutation, variables: { id: shipmentId } });
					let data = JSON.parse(response.data.createEndiciaLabel)
					if (data?.statusCode === 400) {
						console.error(data?.body)
						toast.error(data?.body)
					}
				}
				//#endregion

				//#region FedEx
				if (shipment.carrier.alias.toLowerCase() === 'fedex') {
					await API.graphql({ query: createFedexLabelMutation, variables: { id: shipmentId } });
				}
				//#endregion

				//#region UPS
				if (shipment.carrier.alias.toLowerCase() === 'ups') {
					await API.graphql({ query: createUpsLabelMutation, variables: { id: shipmentId } });
				}

				//#region Clockwork CXT
				if (shipment.carrier.alias.toLowerCase() === 'clockwork') {
					await API.graphql({ query: createCxtLabelMutation, variables: { id: shipmentId } });
				}
				//#endregion
			} catch (error) {
				console.error('generate label error:', error)
				toast.error('Oops! Looks like there was an error trying to process your request')
			}

			//#region Slack
			try {
				let slackAlert = (shipment?.deliveryService?.type === 'TODAY' && shipment?.deliveryService?.tatMax <= 4) ? 'critical-alerts' : 'alerts'
				const slackInput = {
					channel: slackAlert,
					message: `Shipment ${number} (${shipment?.deliveryService?.name}) is OPEN`,
					user: shipment?.shipFrom?.name
				}
				await API.graphql({ query: sendSlackAlert, variables: { input: slackInput } })
			} catch (error) {
				console.error('Error sending slack alert');
			}
			//#endregion

			//#region billing
			await processBilling(shipmentId)
			//#endregion

			toast.success(`Shipment #${number} has been created`)

			const url = `/shipment/${shipmentId}`;
			if (getSetting('shipment_auto_print') === 'Yes') navigate(url, { state: { print: true } })
			else navigate(url)

			showSpinner(false);
		}
	}

	const ShipFromSection = () => {

		const handleShipFromChange = async (shipperId) => {
			showSpinner(true);
			const index = myShippers.findIndex(x => x.shipper?.id === shipperId);
			const shipper = myShippers[index];
			dispatch(storeShipper(shipper));
			setErrors({})
		}

		return <section className='mb-3'>
			<h4>Pickup Location</h4>
			<Form.Select value={myShipper?.shipper?.id} onChange={(e) => handleShipFromChange(e.target.value)}>
				{myShippers.map((item, index) => <option key={index} value={item?.shipper?.id}>{item?.shipper?.name}</option>)}
			</Form.Select>
			<hr />
		</section>
	}

	const CarrierSection = () => {

		const handleCarrierChange = async (carrier) => {
			const newDeliveryServices = deliveryServices.filter(x => x.carrierId === carrier.id);
			const deliveryService = newDeliveryServices.filter(x => !x.disabled)[0]
			let distance = 0;
			if (["IL", "WI"].includes(shipment?.shipTo?.address?.state) && shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy") {
				if (carrier?.name?.includes("IL")) {
					distance = await getDistanceFromChicagoIl(shipment?.shipTo?.address?.location);
				} else if (carrier?.name?.includes("WI")) {
					distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
				}
			} else {
				distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
			}

			setDeliveryServicesByDistance(newDeliveryServices)
			const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);
			setErrors((prev) => ({ ...prev, deliveryService: "" }))
			if (deliveryService) {
				setShipment({
					...shipment,
					deliveryServiceId: deliveryService.id,
					deliveryService: deliveryService,
					carrierId: carrier.id,
					carrier: carrier,
					expectedPickupTime: expectedPickupTime,
					expectedDeliveryTime: expectedDeliveryTime,
					distance: distance
				})
			} else {
				setShipment({
					...shipment,
					deliveryServiceId: null,
					deliveryService: null,
					carrierId: carrier.id,
					carrier: carrier,
					expectedPickupTime: expectedPickupTime,
					expectedDeliveryTime: expectedDeliveryTime,
					distance: distance
				})
				toast.error("Delivery services are not available right now.")
			}
		}

		const handleDeliveryServiceChange = async (deliveryService) => {
			const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);
			setShipment({
				...shipment,
				deliveryServiceId: deliveryService.id,
				deliveryService: deliveryService,
				expectedPickupTime: expectedPickupTime,
				expectedDeliveryTime: expectedDeliveryTime
			})
		}

		const handlePickupDateChange = (selectedDates, dateStr) => {
			const expectedPickupString = `${dateStr} ${shipment?.deliveryService?.pickupBy}`
			const expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
			const expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(shipment?.deliveryService?.tatMax, 'hours')

			setShipment({
				...shipment,
				expectedPickupTime: expectedPickupTime.unix(),
				expectedDeliveryTime: expectedDeliveryTime.unix()
			})
		}

		return (
			<section className='mb-3'>
				<h4>Courier Partner</h4>
				<ButtonList items={carriersByDistance} value={shipment?.carrierId} onChange={handleCarrierChange} />
				{
					shipment?.carrierId !== '-' && <div>
						<hr />
						<h4>Delivery Type</h4>
						<ButtonList items={deliveryServicesByDistance.filter(x => x.carrierId === shipment?.carrierId)}
							value={shipment?.deliveryServiceId} onChange={handleDeliveryServiceChange} />
						<FormError error={errors?.deliveryService} />
					</div>
				}

				{
					shipment?.deliveryService?.type === 'FUTURE' && <div>
						<hr />
						<h4>Deliver On</h4>
						<InputGroup className='mb-3'>
							<InputGroup.Text>
								<i className='fe fe-calendar' />
							</InputGroup.Text>
							<Flatpickr className='form-control' onChange={handlePickupDateChange}
								value={moment.unix(shipment?.expectedPickupTime).toDate()}
								options={{
									altInput: true,
									altFormat: 'F j, Y',
									minDate: moment().add(1, 'days').toDate(),
								}}>
								<input data-input className='form-input' />
							</Flatpickr>
						</InputGroup>
						<FormError error={errors?.future} />
					</div>
				}

				<hr />
			</section>
		)
	}

	const ServiceSection = () => {

		const handleShipmentServiceChange = (service) => {
			let services = shipment?.services;
			if (services) {
				if (services.includes(service)) {
					services = services.filter(x => x !== service);
					if ((getSetting('shipment_photo_id_required') === "Yes")) {
						if (service === "ADULT_SIGNATURE" && services.includes("PHOTO_ID")) {
							services = services.filter(x => x !== "PHOTO_ID");
						}
					}
				} else {
					if (!services.includes(service)) services.push(service);
					if ((getSetting('shipment_photo_id_required') === "Yes")) {
						if (service === "PHOTO_ID" && !services?.includes("ADULT_SIGNATURE")) {
							services.push("ADULT_SIGNATURE")
						}
					}
				}
			} else {
				services = [service];
			}

			setShipment({ ...shipment, services: services });
		}

		return <>

			<section className='mb-3'>
				<h4>Signature Type</h4>
				{
					Object.keys(SignatureType).map((key, index) => {
						return (
							<button key={index}
								disabled={(shipment?.isGreenPhox && (selectedCustomerId?.isGreenPhox) || currCustomerGreenPhox) && key !== "PHOTO_ID"}
								className={`btn btn-${shipment?.services?.includes(key) ? 'success' : 'light'} me-2 mt-2`}
								onClick={() => handleShipmentServiceChange(key)}>
								{SignatureType[key]}
							</button>
						)
					})
				}
			</section>
			<hr />

			<section className='mb-3'>
				<h4>Package Type</h4>
				{
					Object.keys(StorageType).map((key, index) => {

						return (
							<>
								{
									storageTypesSettings?.length > 0 ?
										storageTypesSettings.includes(key) &&
										< button key={index}
											className={`btn btn-${shipment?.services?.includes(key) ? 'success' : 'light'} me-2 mt-2`}
											onClick={() => handleShipmentServiceChange(key)}
										>
											{StorageType[key]}
										</button > :
										< button key={index}
											className={`btn btn-${shipment?.services?.includes(key) ? 'success' : 'light'} me-2 mt-2`}
											onClick={() => handleShipmentServiceChange(key)}
										>
											{StorageType[key]}
										</button >
								}
							</>
						)
					})
				}
			</section >
			<hr />
		</>
	}

	const Summary = () => {
		return (
			<Card>
				<Card.Body>
					<Row className='align-items-center'>
						<Col className='col-6'>
							<section>
								<h6 className='text-uppercase text-muted mb-2'>
									Ship From
								</h6>

							</section>
						</Col>
						<Col className='col-6 text-end'>
							{
								shipment?.isGreenPhox &&
								<img src="/img/greenphox.svg" className='me-2' alt="phox icon" height='30px' width='30px' />
							}
						</Col>
					</Row>
					{
						shipment?.shipFrom && <>
							<div>{shipment?.shipFrom?.name}</div>
							<AddressView address={shipment?.shipFrom?.address} />
						</>
					}
					<hr />
					<section>
						<h6 className='text-uppercase text-muted mb-2'>Ship To</h6>
						<Row>
							<Col>
								{
									shipment?.shipTo ? <>
										<div>{(shipment?.shipTo?.firstName && shipment?.shipTo?.lastName) ? shipment?.shipTo?.firstName + ' ' + shipment?.shipTo?.lastName : shipment?.shipTo?.firstName ? shipment?.shipTo?.firstName : shipment?.shipTo?.name}   </div>
										<AddressView address={shipment?.shipTo?.address} />
									</> : <>-</>
								}
							</Col>
							<Col className='col-auto'>
								{
									(shipment?.shipTo?.address?.address1 && !isNaN(shipment?.distance)) && !['fedex', 'usps', 'ups'].includes(shipment?.carrier?.alias?.toLowerCase()) &&
									<h4 className={`text-end mb-0 text-${shipment?.deliveryService?.maxDistance ? (shipment?.distance <= ((shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && shipment?.carrier?.name?.includes("IL") && shipment?.shipTo?.address?.state?.includes("IL")) ? 55 : shipment?.deliveryService?.maxDistance) ? 'success' : 'danger') : 'success'}`}>{shipment?.distance ?? '-'} mi</h4>
								}
							</Col>
						</Row>

						<hr />
					</section>

					<section>
						<h6 className='text-uppercase text-muted mb-2'>Courier</h6>
						{
							shipment?.carrier ? <>
								<div>{shipment?.carrier?.name}</div>
								<div className='small text-muted'>{shipment?.deliveryService?.name}</div>
							</> : <>-</>
						}

						<hr />
					</section>

					<section>
						<h6 className='text-uppercase text-muted mb-2'>Estimated Pickup By</h6>
						{toLocalDateTime(shipment?.expectedPickupTime, shipment?.shipFrom?.timezone?.id)}
						<hr />
					</section>

					<section>
						<h6 className='text-uppercase text-muted mb-2'>Estimated Delivery By</h6>
						{toLocalDateTime(shipment?.expectedDeliveryTime, shipment?.shipFrom?.timezone?.id)}
					</section>
				</Card.Body>
			</Card>
		)
	}

	const enrollPatientIntoGreenPhox = (e) => {
		if (e.target.checked === true) {
			let services = shipment?.services
			services.push('ADULT_SIGNATURE')
			setShipment({ ...shipment, isGreenPhox: true, services })
			let newCarriers = carriers.filter(x => x.name !== 'FedEx' && x.name !== 'USPS' && x.name !== 'UPS');
			setCarriersByDistance(newCarriers);
			setGreenPhoxDelivery(true)
			setUpdateCustomerAddress(true);
		} else if (e.target.checked === false) {
			let services = shipment?.services
			let indexOfSignature = services.indexOf('ADULT_SIGNATURE')
			if (indexOfSignature !== -1) {
				services?.splice(indexOfSignature, 1)
			}
			setGreenPhoxDelivery(false)
			setShipment({ ...shipment, isGreenPhox: false, services })
			setCarriersByDistance(carriers);
		}
		setGreenPhoxDelivery(e.target.checked)
		setCurrCustomerGreenPhox(e.target.checked)
	}


	const handleBoxSelect = (e) => {
		setShipment({ ...shipment, boxId: e.value, boxLabel: e.label })
	}

	// search shipment boxes
	const searchBoxes = async (keyword) => {
		try {
			const apiName = "api";
			const path = "/search/shipment-box";
			let init = {
				body: {
					sort: [
						{ "_score": { "order": "desc" } },
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						"bool": {
							"must": [
								{
									"match": {
										"shipperId": myShipper?.shipper?.id
									}
								},
								{
									match: {
										"status": "AVAILABLE"
									}
								}
							],
						}
					}
				}
			}

			if (keyword) {
				let fields = [
					"name",
					"number"
				]
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			let { hits } = await API.post(apiName, path, init);
			hits = hits.hits.map((item) => item?._source)
			let items = [];

			if (hits.length > 0) {
				hits.forEach(item => {
					items.push({
						label: item.number || item?.name,
						value: item.id
					});
				});
			}
			return items;

		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};



	const selectOtherBoxService = () => {
		let serviceArray = shipment?.services
		let sign = serviceArray.indexOf('ADULT_SIGNATURE');
		if (sign !== -1) serviceArray.splice(sign, 1);
		let photoId = serviceArray.indexOf("PHOTO_ID");
		if (photoId !== -1) serviceArray.splice(photoId, 1);

		setCarriersByDistance(carriers)
		setShipment({ ...shipment, isGreenPhox: false, boxId: null, box: null, services: serviceArray })
	}

	const updatedServices = () => {
		let newCarriers = carriers.filter(x => x.name !== 'FedEx' && x.name !== 'USPS' && x.name !== 'UPS');
		setCarriersByDistance(newCarriers);
		let serviceArray = shipment?.services;
		if (!serviceArray.includes('ADULT_SIGNATURE')) serviceArray.push('ADULT_SIGNATURE')
		setShipment({ ...shipment, services: serviceArray, isGreenPhox: true })
	}



	return (
		<>
			<PageHeader name='Create Shipment' className='container-fluid'>
				<button className='btn btn-light' onClick={() => showQRModal(true)}>Scan QR</button>
			</PageHeader>

			<Spinner display={spinner}>
				<Container fluid>

					<Row>
						<Col xl={8}>

							<ShipFromSection />

							<section className='mb-3'>
								<h4>Delivery Location</h4>
								<AsyncSelect cacheOptions defaultOptions
									key={shipment?.shipTo?.id}
									value={shipment?.shipTo?.id === 'NEW' ? { label: ' -- Add New Patient -- ', value: 'NEW' } : { value: shipment?.shipTo?.id, label: shipment?.shipTo?.name }}
									loadOptions={searchCustomer} onChange={handleShipToSelect}
								/>
								<FormError error={errors?.shipTo || errors.distance} />

								{
									shipment?.shipTo?.id && <>
										<Row>
											<Col lg={6}>
												<Form.Group className='mt-3'>
													<FormLabel required={true}>First Name</FormLabel>
													<Form.Control type='text' name='firstName' placeholder='e.g. John' value={shipment?.shipTo?.firstName || ''} onChange={handleShipToChange} />
												</Form.Group>
												<FormError error={errors?.shipTo_firstName} />
											</Col>
											<Col lg={6}>
												<Form.Group className='mt-3'>
													<FormLabel>Last Name</FormLabel>
													<Form.Control type='text' name='lastName' placeholder='e.g. Doe' value={shipment?.shipTo?.lastName || ''} onChange={handleShipToChange} />
												</Form.Group>
												<FormError error={errors?.shipTo_lastName} />
											</Col>
										</Row>
										<Form.Group className='mt-3'>
											<FormLabel>Company / Care Of</FormLabel>
											<Form.Control type='text' name='company' placeholder='e.g. ABC Co' value={shipment?.shipTo?.company || ''} onChange={handleShipToChange} />
										</Form.Group>
										<Form.Group className='mt-3'>
											<FormLabel>Medical Record Number (MRN)</FormLabel>
											<Form.Control type='text' name='extId' placeholder='e.g. 3247t3284-sZfs322' value={shipment?.shipTo?.extId || ''} onChange={handleShipToChange} disabled={(selectedCustomerId?.id !== "NEW" && selectedCustomerId?.id !== "new")} />
											<FormError error={errors?.mrn} />
										</Form.Group>

										<Row className='mt-3'>
											<Col>
												<Form.Group>
													<FormLabel required={true}>Address Line 1</FormLabel>
													<GoogleAddressField name='address1' value={shipment?.shipTo?.address?.address1}
														error={errors?.address1} isInvalid={!!errors?.address1}
														onChange={handleAddress1Change} onSelect={handleAddressSelect} />
													<FormError error={errors?.shipTo_Address1} />
												</Form.Group>
											</Col>

											<Col>
												<Form.Group>
													<FormLabel>Address Line 2</FormLabel>
													<Form.Control type='text' name='address2' placeholder='e.g. Suite 100' value={shipment?.shipTo?.address?.address2 || ''} onChange={handleAddressChange} />
												</Form.Group>
											</Col>
										</Row>

										<Row className='mt-3'>
											<Col lg={4}>
												<Form.Group>
													<FormLabel required={true}>City</FormLabel>
													<Form.Control type='text' name='city' placeholder='e.g. Seattle' value={shipment?.shipTo?.address?.city || ''} onChange={handleAddressChange} disabled />
													<FormError error={errors?.shipTo_City} />
												</Form.Group>
											</Col>
											<Col lg={4}>
												<Form.Group>
													<FormLabel required={true} >State</FormLabel>
													<Form.Control type='text' name='state' placeholder='e.g. WA' value={shipment?.shipTo?.address?.state || ''} onChange={handleAddressChange} disabled />
													<FormError error={errors?.shipTo_State} />
												</Form.Group>
											</Col>
											<Col lg={4}>
												<Form.Group>
													<FormLabel required={true}>Postal Code</FormLabel>
													<Form.Control type='text' name='postalCode' key={shipment?.shipTo?.address?.postalCode} placeholder='e.g. 98105' value={shipment?.shipTo?.address?.postalCode} onChange={handleAddressChange} disabled />
													<FormError error={errors?.shipTo_PostalCode} />
												</Form.Group>
											</Col>
										</Row>

										<Row className='mt-3'>
											<Col>
												<Form.Group>
													<FormLabel>Email</FormLabel>
													<Form.Control type='email' name='email' placeholder='e.g. john@pharmacy.com' value={shipment?.shipTo?.email || ''} onChange={handleShipToChange} />
													<FormError error={errors?.email} />
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<FormLabel required={true}>Phone </FormLabel>
													<PhoneInput key={shipment?.customerId} country='us' onlyCountries={['us']} value={shipment?.shipTo?.phone} placeholder='e.g. 998-776-5543' onChange={handleShipToPhoneChange} />
													<FormError error={errors?.phone} />
												</Form.Group>
											</Col>
										</Row>
									</>
								}
								{
									(shipment?.customerId && !selectedCustomerId?.isGreenPhox && getSetting('enroll_green_phox') === 'Yes') &&
									<div className="row align-items-center mt-4">
										<div className="col">
											<h4>Enroll GreenPhox</h4>
											<small className="text-muted">
												Enroll patient into GreenPhox’s package recovery and reuse program. Patient will receive GreenPhox messaging for their delivery notifications.
											</small>
										</div>
										<div className="col-auto">
											<div className="form-check form-switch">
												<input className='form-check-input' type='checkbox' checked={greenPhoxDelivery} name='isGreenPhox' onChange={(e) => enrollPatientIntoGreenPhox(e)} />
												<label className="form-check-label" for="subscriptionsSwitchOne"></label>
											</div>
										</div>
									</div>
								}
								<hr />
							</section>

							<CarrierSection />

							<ServiceSection />

							<div className='mt-4'>
								<>
									{
										greenPhoxDelivery &&
										<section className='mb-3'>
											<Row>
												<Col xl={4} lg={6} md={6} className='mb-3' >
													<div>
														<h4>Box Type</h4>
														<button className={`btn btn-${shipment?.isGreenPhox ? 'success' : 'light'} me-2 mt-2`} onClick={() => updatedServices()}>
															GreenPhox
														</button>
														<button className={`btn btn-${!shipment?.isGreenPhox ? 'success' : 'light'} me-2 mt-2`} onClick={() => selectOtherBoxService()}>
															Other
														</button>
													</div>
												</Col>

												{
													shipment.isGreenPhox &&
													<Col xl={8} lg={6} md={6}>
														<h4>
															Box Number <small className='text-danger'>*</small>
														</h4>
														<AsyncSelect cacheOptions defaultOptions
															defaultValue={shipment?.boxId ? { value: shipment?.boxId, label: shipment?.boxLabel } : { label: 'Select Box', value: 'select box' }}
															loadOptions={searchBoxes} onChange={handleBoxSelect} />
														<FormError error={errors?.box} />
													</Col>
												}
											</Row>
											<hr />
										</section>
									}
								</>
							</div>

							<div className='mt-4'>
								<h4>
									Order / Reference Number
									{getSetting('shipment_order_number_required') === 'Yes' && <span className='text-danger ms-2'>*</span>}
								</h4>
								<Form.Control type='text' placeholder='e.g. 123422' value={shipment?.extId || ''} onChange={(e) => setShipment({ ...shipment, extId: e.target.value?.trim() })} />
								<FormError error={errors?.extId} />
							</div>

							<div className='mt-4' onWheel={(e) => e.target.blur()}>
								<h4>
									Package Weight (in lbs)
									{['fedex', 'usps', 'ups'].includes(shipment?.carrier?.alias?.toLowerCase()) && <span className='text-danger ms-2'>*</span>}
								</h4>
								<Form.Control type='number' placeholder='e.g. 2' defaultValue={shipment?.weight || ''} onChange={(e) => setShipment({ ...shipment, weight: e.target.value })} />
								<FormError error={errors?.weight} />
							</div>

							<Form.Group className='mt-4'>
								<h4>Delivery Instructions</h4>
								<textarea name='deliveryInstructions' className='form-control' rows={2}
									placeholder='e.g. door number, entry code' value={shipment?.deliveryInstructions || ''}
									onChange={(e) => setShipment({ ...shipment, deliveryInstructions: e.target.value })}
								/>
							</Form.Group>

							<Shipmentpackages packages={shipment?.items} packageCount={shipment.packageCount} errors={errors} handleItems={handleItems} myShipper={myShipper} handlePackages={handlePackageCount} scanPackageData={getSetting('phox_tail_required') === 'Yes'} packageErrors={packageErrors} setPackageErrors={setPackageErrors} />
							<hr />

							<button className='btn btn-dark' onClick={() => createShipment()}>Create Shipment</button>
							<Link className='btn btn-link ms-1 text-muted' to='/shipment'>Cancel</Link>
						</Col>
						<Col>
							<Summary />
						</Col>
					</Row>
				</Container>
			</Spinner>

			<Modal show={qrModal} onHide={() => showQRModal(false)}>
				<Modal.Header>
					<div className='text-dark'>Scan QR Code</div>
					<Button variant='dark' onClick={() => showQRModal(false)} >
						Close
					</Button>
				</Modal.Header>
				<Modal.Body>
					<div>

						<QrScanner
							onDecode={handleCameraScan}
							onScan={handleCameraScan}
							onError={handleCameraError}
							facingMode={facingMode}
							onCameraPermission={handlePermission}
							ref={scannerRef}
						/>
					</div>
				</Modal.Body>
			</Modal>

			<BarcodeReader onError={handleScanError} onScan={handleScan} />
		</>
	)
}

export default ShipmentCreate;
import React from 'react';
import { toTitleCase } from '../helpers';
import ShipmentStatus from './../data/shipment-status.json';
import BoxStatus from "../data/box-status.json"
import { Outlet } from 'react-router-dom';
export const StatusBadge = ({ status, size }) => {
	const text = ShipmentStatus[status]
	let color = 'primary-soft';

	if (status === 'OPEN') color = 'light';
	else if (status === 'IN_TRANSIT') color = 'warning-soft';
	else if (status === 'OUT_FOR_DELIVERY') color = 'warning-soft';
	else if (status === 'DELIVERED') color = 'success-soft';
	else if (status === 'EXCEPTION') color = 'danger-soft';
	else if (status === 'RETURNED') color = 'purple';
	else if (status === 'OUT_FOR_RETURN') color = 'purple-soft';

	return (
		<div className={size || 'h3 mb-0'}>
			<span className={`badge bg-${color}`}>{text}</span>
		</div>
	);
};
export const BoxStatusBadge = ({ status, size }) => {
	const text = BoxStatus[status]
	let color = 'primary-soft';

	if (status === 'AVAILABLE') color = 'success';
	else if (status === 'IN_USE') color = 'primary-soft'
	else if (status === 'OUT_FOR_RETURN') color = 'purple-soft';
	else if (status === 'RETURNED') color = 'purple';
	else if (status === 'DAMAGED') color = 'danger';
	else if (status === 'RETIRED') color = 'danger';

	return (
		<div className={size || 'h3 mb-0'}>
			<span className={`badge bg-${color}`}>{text}</span>
		</div>
	);
};

export const InviteStatusBadge = ({ status, size }) => {
	let color = status === 'Expired' ? 'danger-soft' : 'success-soft';
	return (
		<div className={size ? size : 'h3 mb-0'}>
			<span className={`badge bg-${color}`}>{status}</span>
		</div>
	);
};

export const UserRoleBadge = ({ role, size }) => {
	let color = 'primary-soft';
	if (role === 'OWNER') color = 'danger-soft';
	else if (role === 'EDITOR') color = 'primary-soft';
	else if (role === 'VIEWER') color = 'secondary-soft';

	return (
		<div className={size || 'h3 mb-0'}>
			<span className={`badge bg-${color} text-`}>{toTitleCase(role)}</span>
		</div>
	);
};

